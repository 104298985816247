import { Chip, Grid, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { MdOutlineMoreHoriz } from "react-icons/md";
import BillingCard from "./BillingCard.jsx";
import CustomButton from "../UI/CustomButton.jsx";
import BinIcon from "../../Assets/Images/bin_icon.png";
import CancelPlan from "./CancelPlan.jsx";
import UpdatePlan from "./UpgradePlan.jsx";
import {PlanNames}  from "../../constants";

const useStyles = makeStyles((theme) => ({
  headContainer: {
    display: "flex",
    alignItems: "center",
    "& h1": {
      ...theme.typography.heading36,
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: "26px",
        lineHeight: "30px",
      },
    },
  },
  chip: {
    marginLeft: "10px",
    "&.MuiChip-root": {
      backgroundColor: theme.palette.lightGreen,
      borderRadius: "5px",
      height: "fit-content",
    },
    "& .MuiChip-label": {
      ...theme.typography.subHead14,
      padding: "5px 12px 5px 7px",
      color: theme.palette.white,
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        padding: "3px 7px 3px 7px",
      },
    },
  },
  buttonsContainer: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
  },
  changePlanButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    padding: "5px 40px !important",
    minWidth: "200px !important",
    marginRight: "10px !important",
  },
  moreButton: {
    padding: "5px !important",
    minWidth: "40px !important",
    [theme.breakpoints.down("sm")]: {
      flex: "1",
    },
  },
  subText: {
    margin: 0,
    ...theme.typography.body16,
    [theme.breakpoints.down("sm")]: {
      marginTop: "3px",
      ...theme.typography.body14,
    },
  },
  planDetails: {
    margin: 0,
    ...theme.typography.body16,
    textAlign: "right",
    [theme.breakpoints.down(885)]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      ...theme.typography.body14,
    },
  },
  rightContainer: {
    [theme.breakpoints.down(885)]: {
      marginTop: "20px !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "20px !important",
    },
  },
  menuIcon: {
    height: "25px",
    width: "25px",
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      ...theme.typography.body16,
    },
  },
}));

const BillingHead = ({ activeSubscription, activeSubscriptionDetails }) => {
  const classes = useStyles();
  const { search } = useLocation();
  const reason =
    search?.split("?")[1]?.split("=")[0] === "reason"
      ? search?.split("?")[1]?.split("=")[1]
      : false;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCancelPlanDialog, setShowCancelPlanDialog] = React.useState(false);
  const [showUpdatePlanDialog, setShowUpdatePlanDialog] = React.useState(
    reason === "subscribeForSearch" ||
      reason === "takedown" ||
      reason === "savedMatches"
  );

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeCancelPlanDialog = () => {
    setShowCancelPlanDialog(false);
  };

  const openCancelPlanDialog = () => {
    setShowCancelPlanDialog(true);
  };

  const closeUpdatePlanDialog = () => {
    setShowUpdatePlanDialog(false);
  };

  const openUpdatePlanDialog = () => {
    setShowUpdatePlanDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BillingCard>
      <CancelPlan open={showCancelPlanDialog} onClose={closeCancelPlanDialog} />
      <UpdatePlan
        open={showUpdatePlanDialog}
        onClose={closeUpdatePlanDialog}
        activeSubscription={activeSubscription}
        reason={reason}
      />

      <Grid
        container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <div className={classes.headContainer}>
            <h1>{PlanNames[activeSubscription] || "Free Plan"}</h1>
            <Chip label="Current Plan" className={classes.chip} />
          </div>
          {activeSubscription && (
            <p className={classes.subText}>
              Next bill on{" "}
              {activeSubscriptionDetails?.nextPayment &&
                moment
                  .unix(activeSubscriptionDetails.nextPayment)
                  .format("MMMM D, YYYY")}
            </p>
          )}
        </Grid>
        <Grid item className={classes.rightContainer}>
          {activeSubscription && (
            <>
              {activeSubscriptionDetails?.paymentInUsd && (
                <p className={classes.planDetails}>
                  ${activeSubscriptionDetails?.paymentInUsd}/month{" "}
                </p>
              )}
            </>
          )}

          <div className={classes.buttonsContainer}>
            {activeSubscription ? (
              <>
                <CustomButton
                  yellow
                  className={classes.changePlanButton}
                  onClick={openUpdatePlanDialog}
                >
                  Change Plan
                </CustomButton>
                <CustomButton
                  className={classes.moreButton}
                  aria-controls={open ? "more-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MdOutlineMoreHoriz />
                </CustomButton>
              </>
            ) : (
              <CustomButton
                yellow
                className={classes.changePlanButton}
                onClick={openUpdatePlanDialog}
              >
                Subscribe
              </CustomButton>
            )}
          </div>
        </Grid>
      </Grid>
      <Menu
        id="more-menu"
        aria-labelledby="more-menu"
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
          },
        }}
      >
        <MenuItem onClick={openCancelPlanDialog} className={classes.menuItem}>
          <ListItemIcon>
            <img src={BinIcon} alt="bin icon" className={classes.menuIcon} />
          </ListItemIcon>
          Cancel Subscription
        </MenuItem>
      </Menu>
    </BillingCard>
  );
};

export default BillingHead;
