import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BsDownload } from "react-icons/bs";
import moment from "moment";
import React from "react";
import CustomButton from "../UI/CustomButton.jsx";
import BillingCard from "./BillingCard.jsx";
import {PlanNames} from "../../constants";

const useStyles = makeStyles((theme) => ({
  billingHistory: {
    marginTop: "10px",
  },
  headContainer: {
    "& h2": {
      ...theme.typography.body20,
      fontweight: 500,
      margin: 0,
    },
  },
  head: {
    paddingBottom: "10px",
    borderBottom: `1px solid ${theme.palette.grey4}`,
  },
  downloadButton: {
    padding: "10px 30px !important",
    fontSize: "18px !important",

    lineHeight: "16px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      minWidth: "40px !important",
      padding: "7px 0 !important",
    },
    "& svg": {
      marginRight: "8px",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
  },
  mobileDownloadButton: {
    marginTop: "10px !important",
    fontSize: "14px !important",
    lineHeight: "16px !important",
    fontWeight: "400 !important",
    "& svg": {
      marginRight: "8px",
    },
  },
  tableDownloadButton: {
    padding: "10px 15px !important",
    border: `2px solid ${theme.palette.grey6} !important`,
    boxShadow: "none !important",
    fontSize: "18px !important",
    lineHeight: "16px !important",
    fontWeight: "400 !important",
    "& svg": {
      marginRight: "8px",
    },
  },
  tableContainer: {
    margin: "7px",
    marginTop: "20px",
  },
  tableHead: {
    background: theme.palette.grey4,

    "& .MuiTableCell-root ": {
      border: "none",
      ...theme.typography.body18,
      padding: "15px",
    },
  },
  tableBody: {
    "& .MuiTableCell-root ": {
      borderBottom: `1px solid ${theme.palette.grey4}`,
      ...theme.typography.body18,
      padding: "18px",
    },
  },
  invoicesHead: {
    minWidth: "250px",
  },
  incoivesColumn: {
    minWidth: "250px",
    "& p": {
      margin: 0,
      ...theme.typography.body18,
      fontWeight: 700,
    },
    "& span": {
      marginTop: "5px",
      ...theme.typography.body16,
    },
  },
  statusGreen: {
    color: theme.palette.lightGreen,
    fontWeight: 500,
  },
  statusRed: {
    color: theme.palette.red,
    fontWeight: 500,
  },
  mobileDataContainer: {
    paddingTop: "25px",
    paddingBottom: "25px",
    borderBottom: `1px solid ${theme.palette.grey4}`,
  },
  section1: {
    "& h2": {
      ...theme.typography.heading18,
      fontWeight: "700",
      margin: 0,
      marginBottom: "7px",
    },
    "& p": {
      ...theme.typography.body14,
      margin: 0,
      marginBottom: "7px",
    },
  },
  noData: {
    textAlign: "center",
  },
}));

const BillingHistory = ({ bills }) => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const onDownload = (url) => {
    window.open(url);
  };

  return (
    <div className={classes.billingHistory}>
      <BillingCard>
        <Grid
          container
          flexdirection="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.head}
        >
          <Grid item>
            <div className={classes.headContainer}>
              <h2>Billing</h2>
            </div>
          </Grid>
          {/* {bills.length > 0 && (
            <Grid item>
              <CustomButton className={classes.downloadButton}>
                <BsDownload />
                {isMobileScreen ? "" : "Download"}{" "}
              </CustomButton>
            </Grid>
          )} */}
        </Grid>
        {isMobileScreen ? (
          bills.length > 0 ? (
            bills.map((ele, key) => (
              <MobileDataWrapper
                key={key}
                invoice={ele.invoice}
                amount={ele.payment_in_usd}
                date={ele.date}
                invoiceDownload={() => onDownload(ele.invoice_url)}
                plan={ele.plan}
                status={
                  ele.completed ? (
                    <p className={classes.statusGreen}>Paid</p>
                  ) : (
                    <p className={classes.statusRed}>Pending</p>
                  )
                }
              />
            ))
          ) : (
            <p className={classes.noData}>No Invoices</p>
          )
        ) : (
          <div className={classes.tableContainer}>
            <TableContainer>
              <Table>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell align="left" className={classes.invoicesHead}>
                      Invoices
                    </TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Plan</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {bills.map((element, key) => (
                    <TableRow key={key}>
                      <TableCell
                        align="left"
                        className={classes.incoivesColumn}
                      >
                        <p>{element.invoice}</p>
                        <span>
                          {moment.unix(element.date).format("MMMM YYYY")}
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        {element.payment_in_usd/100}
                      </TableCell>
                      <TableCell align="left">
                        {moment.unix(element.date).format("MMM D, YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {element.completed ? (
                          <p className={classes.statusGreen}>Paid</p>
                        ) : (
                          <p className={classes.statusRed}>Pending</p>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {PlanNames[element.plan]}
                      </TableCell>
                      <TableCell align="left">
                        <CustomButton
                          className={classes.tableDownloadButton}
                          onClick={() => onDownload(element.invoice_url)}
                        >
                          <BsDownload />
                          Download
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {bills.length === 0 && (
                <p className={classes.noData}>No Invoices</p>
              )}
            </TableContainer>
          </div>
        )}
      </BillingCard>
    </div>
  );
};

const MobileDataWrapper = ({
  plan,
  date,
  amount,
  invoice,
  status,
  invoiceDownload,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.mobileDataContainer}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexdirecton="row"
      >
        <Grid item>
          <div className={classes.section1}>
            <h2>{invoice}</h2>
            <p>{moment.unix(date / 1000).format("MMMM YYYY")}</p>
            <p>{plan}</p>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.section1}>
            <p> {moment.unix(date / 1000).format("MMM D, YYYY")}</p>
            <h2>{amount}</h2>
            <p>{status}</p>
          </div>
        </Grid>
      </Grid>
      <CustomButton
        className={classes.mobileDownloadButton}
        onClick={invoiceDownload}
      >
        <BsDownload />
        Download
      </CustomButton>
    </div>
  );
};

export default BillingHistory;
