import axios from "axios";

export const BASE_URL =
  process.env.REACT_APP_MODE === "local"
    ? "http://localhost:1403/api"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://api-staging.goloti.com/api"
    : "https://api.goloti.com/api";

const API = axios.create({
  baseURL: BASE_URL,
});

API.interceptors.request.use((request) => {
  if (request.url.endsWith("authenticate")) return request;

  // Temporary just for bypass of onboarding form
  // if (request.url.endsWith("face")) return request;
  if (request.url.endsWith("search")) return request;
  if (request.url.endsWith("searches")) return request;

  if (request.url.endsWith("subscribe")) return request;

  if (request.url.endsWith("asset/save")) return request;

  // For onboarding flow update user details
  if (request.url.endsWith("updateUser")) return request;

  const token = localStorage.getItem("token");
  request.headers.Authorization = `Bearer ${token}`;
  request.headers["Content-Type"] = "application/json";

  return request;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default API;
