import React from "react";

export const PlanNames = {
  price_1MNVjiAFrcXOlCub0vwDbaQO: "loti takedown",
  price_1MNVjtAFrcXOlCub3mrf40ut: "loti monitor",
  price_1MNVjcAFrcXOlCubLBqX8Vpl: "loti creator",
};

export const plans = [
  // {
  //   name: "Standard Plan",
  //   description:
  //     "Twice a month image and video alerts as well as unlimited access to view your image results.",
  //   points: [
  //     "One image match per month",
  //     "View all image results at any time",
  //     "Two alerts per month",
  //     "Access to DMCA takedown process",
  //   ],
  //   price: "13",
  //   gatewayIdentifier: "loti-standard",
  // },
  {
    name: "loti takedown",
    description: (
      <>
        We found both image and video results that closely match your upload.
        <br />
        <br />
        With our Advanced Plan, you can view all image and video results and get
        access to our DMCA process to take them down.
      </>
    ),
    descriptionTxt:
      " Twice a month image and video alerts with unlimited access to view your image results. It also includes 2 free takedowns.",
    points: [
      "One image match per month",
      "Unlimited access to all image and video results",
      "Four alerts per month",
      "Access to DMCA takedown process",
    ],
    price: "25",
    gatewayIdentifier: "price_1MNVjiAFrcXOlCub0vwDbaQO",
  },
  {
    name: "loti monitor",
    description:
      "With our Basic Alerts plan once a month we will send you a notification if we find any images or videos that match your image.  ",
    descriptionTxt:
      "If we find any images or videos of you on the internet, we will notify you.",
    points: ["One image match per month", "One alert per month"],
    sub: (
      <>
        (One image will accurately detect
        <br /> 98% of images and videos)
      </>
    ),
    price: "8",
    gatewayIdentifier: "price_1MNVjtAFrcXOlCub3mrf40ut",
  },
  {
    name: "loti creator",
    points: [
      "General Face Search (98% Accurate)",
      "20 Exact Match Images/Videos",
      "Customizable (Daily to Weekly) Alerts",
      "Unlimited DMCA takedowns",
    ],
    descriptionTxt:
      "Weekly image and video alerts and unlimited access to your image and video results. This plan includes unlimited free takedowns.",
    price: "100",
    gatewayIdentifier: "price_1MNVjcAFrcXOlCubLBqX8Vpl",
  },
];

export const SEIREN_PLAN = {
  name: "loti seiren",
  points: [
    "General Face Search (98% Accurate)",
    "20 Exact Match Images/Videos",
    "Customizable (Daily to Weekly) Alerts",
    "Unlimited DMCA takedowns",
  ],
  descriptionTxt:
    "Weekly image and video alerts and unlimited access to your image and video results. This plan includes unlimited free takedowns.",
  price: "0",
  gatewayIdentifier: "price_1Mbi4bAFrcXOlCub88rmE65p",
}
