import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  progress: {
    color: `${theme.palette.darkGreen} !important`,
    margin: "10px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const CustomSpinner = ({ center }) => {
  const classes = useStyles();
  if (center) {
    return (
      <div className={classes.center}>
        <CircularProgress className={classes.progress} />
      </div>
    );
  }
  return <CircularProgress className={classes.progress} />;
};

export default CustomSpinner;
