/* eslint-disable */
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Script = () => {
  if (process.env.REACT_APP_MODE === "staging") {
    return null;
  }
  return (
    <HelmetProvider>
      <Helmet>
        <script type="text/javascript">
          {`  window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', '46c500162cd6ade87df4cd3af1881ec5e2a6dad3', { region: 'eu' });`}
        </script>
        {/* Google Tag Manager */}
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KXV58JQ');`}</script>
        {/* End Google Tag Manager */}

        {/* Meta Pixel Code */}
        <script>{`  !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '754146799023419');
              fbq('track', 'PageView');
`}</script>
        <noscript>{`<img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=754146799023419&ev=PageView&noscript=1"
/>`}</noscript>
        {/* End Meta Pixel Code */}
        {/* Google tag (gtag.js) - */}

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-244275139-1"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-244275139-1');`}
        </script>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-GQ5R0S06SN"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-GQ5R0S06SN');`}
        </script>

        <script type="text/javascript">
          {`
          window.__lc = window.__lc || {};
    window.__lc.license = 14483418;
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`}
        </script>
        <noscript>
          {`<a href="https://www.livechat.com/chat-with/14483418/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>")`}
        </noscript>
      </Helmet>
    </HelmetProvider>
  );
};

export default Script;

/* eslint-enable */
