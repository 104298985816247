import { Alert, Button, StyledEngineProvider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AuthWrapper from "../../Components/AuthWrapper/AuthWrapper.jsx";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import { resendTokenAction } from "../../Store/Auth/actions";

const useStyles = makeStyles((theme) => ({
  head: {
    marginBottom: "15px !important",
    textAlign: "center",
  },
  head2: {
    marginBottom: "5px !important",
    textAlign: "center",
    fontWeight: 700,
  },

  resendButton: {
    ...theme.typography.button,
    color: theme.palette.lightGreen,
    borderColor: theme.palette.lightGreen,
    width: "100%",
    margin: "5px",
    marginTop: "10px",
    height: "55px",
    textTransform: "none",
    "&:hover": {
      borderColor: theme.palette.lightGreen,
    },
  },
  typoBottom: {
    marginTop: "25px",
    textAlign: "center",
  },
  greenFont: {
    color: theme.palette.lightGreen,
  },
}));

const Confirmation = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { loading, error, errorMessage, resendEmailSend } = useSelector(
    (reducerState) => reducerState.authReducer
  );

  const resendEmail = () => {
    dispatch(resendTokenAction(state.email));
  };

  return (
    <AuthWrapper hideBottomLine>
      <StyledEngineProvider injectFirst>
        <Typography variant="body20" className={classes.head}>
          Welcome to Loti! <br />
          {state?.email && (
            <>
              We {resendEmailSend ? "have resend" : "send an"} email to{" "}
              <span className="bold">{state.email}</span>
            </>
          )}
        </Typography>
        <Typography variant="body20" className={classes.head}>
          Checkyour inbox to activate your account.
        </Typography>
        {!resendEmailSend && (
          <>
            <Typography variant="head18" className={classes.head2}>
              Didn’t get your email?
            </Typography>
            {error && (
              <Alert className={classes.alert} severity="error">
                {errorMessage}
              </Alert>
            )}
            {loading ? (
              <CustomSpinner />
            ) : (
              <Button
                variant="outlined"
                className={classes.resendButton}
                onClick={resendEmail}
              >
                Resend Email
              </Button>
            )}
          </>
        )}

        <Typography variant="body16" className={classes.typoBottom}>
          Not getting the email? <br />
          Check your spam/junk folder.
        </Typography>
      </StyledEngineProvider>
    </AuthWrapper>
  );
};

export default Confirmation;
