import { makeStyles } from "@mui/styles";
import React from "react";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  greenBox: {
    background: theme.palette.lightGreen2,
    padding: "35px",
    "& h1": {
      textAlign: "center",
      ...theme.typography.heading44,
      margin: 0,
      marginBottom: "35px",
    },
    "& p": {
      textAlign: "center",
      ...theme.typography.body24,
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 10px",
      "& h1": {
        marginTop: "10px",
        fontSize: "32px",
      },
      "& p": {
        marginTop: "10px",
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: "10px",
      },
    },
  },
  question: {
    margin: 0,
    marginTop: "25px",
    textAlign: "center",
    ...theme.typography.heading28,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  buttonContainer: {
    display: "flex",
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  yesButton: {
    marginRight: "7px !important",
    height: "100px !important",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0px !important",
      height: "auto !important",
      fontSize: "16px !important",
      maxWidth: "240px !important",
    },
  },
  noButton: {
    marginLeft: "7px !important",
    height: "100px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0 !important",
      marginTop: "10px !important",
      height: "auto !important",
      fontSize: "16px !important",
      maxWidth: "240px !important",
    },
  },
  lastInfo: {
    margin: 0,
    marginTop: "20px",
    ...theme.typography.body18,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
}));

const Step6 = ({ onNext, onClickNo, imageFound }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.greenBox}>
        <h1>
          {imageFound ? (
            "We've got your back."
          ) : (
            <>
              Great news!
              <br /> We didn&apos;t find any current matches.
            </>
          )}
        </h1>
        <p>
          {imageFound
            ? "More than 33% of people have been subjected to non-consensual sharing of images and videos. Loti is here to help you monitor and takedown unwanted images and videos from the internet."
            : "Did you know? 33% of people will be subjected to non-consensual image and video sharing at some point in their life."}
        </p>
      </div>
      <h3 className={classes.question}>
        {" "}
        {imageFound
          ? "Would you like to take action now?"
          : "Are you interested in being notified if we find any media of you online?"}
      </h3>
      <div className={classes.buttonContainer}>
        <CustomButton yellow className={classes.yesButton} onClick={onNext}>
          Yes
        </CustomButton>
        <CustomButton className={classes.noButton} onClick={onClickNo}>
          No
        </CustomButton>
      </div>
      {imageFound && (
        <p className={classes.lastInfo}>
          It&apos;s okay if you need a break, you can return at anytime.
        </p>
      )}
    </div>
  );
};

export default Step6;
