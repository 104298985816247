import { all } from "redux-saga/effects";

import AuthSaga from "./Auth/saga";
import DMCASaga from "./DMCA/saga";
import MatchesSaga from "./Matches/saga";
import UploadsSaga from "./Uploads/saga";
import UserSaga from "./User/saga";
import SubscriptionSaga from "./Subscriptions/saga";
import OwnUrlSaga from "./OwnUrl/saga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    UserSaga(),
    MatchesSaga(),
    DMCASaga(),
    SubscriptionSaga(),
    UploadsSaga(),
    OwnUrlSaga(),
  ]);
}
