import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import {
  clearfacesAction,
  deleteAssetAction,
  getUploadedImagesAction,
  saveAssetAction,
  uploadAssetAction,
} from "../../Store/Uploads/actions";
import UploadMediaCard from "../../Components/UploadMedia/UploadMediaCard.jsx";
import CustomSpinner2 from "../../Components/UI/CustomSpinner2.jsx";
import FaceCheck from "../../Components/UploadMedia/FaceCheck.jsx";
import UploadBox from "../../Components/UploadMedia/UploadBox.jsx";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import UploadCard from "../../Components/UploadMedia/UploadCard.jsx";
import {
  clearMatchErrorAction,
  searchAssetAction,
} from "../../Store/Matches/actions";
import CustomButton from "../../Components/UI/CustomButton.jsx";
import ConfirmationDialog from "../../Components/Common/ConfirmationDialog.jsx";
import UpgradePlanDialog from "../../Components/Common/UpgradePlanDialog.jsx";
import { getActiveSubscriptionAction } from "../../Store/Subscriptions/actions";

const useStyles = makeStyles((theme) => ({
  uploadMediaContainer: {
    height: "100%",
  },
  bodyContainer: {
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  noPlanMessageContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& h2": {
      ...theme.typography.heading36,
      fontWeight: 500,
      margin: 0,
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    "& p": {
      textAlign: "center",
      ...theme.typography.body24,
      margin: 0,
      marginBottom: "15px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    "& button": {
      maxWidth: "250px !important",
    },
  },

  link: {
    color: theme.palette.lightGreen,
  },

  content: {
    ...theme.typography.heading36,
    textAlign: "center",
    padding: "0 10px",
    margin: 0,
    marginBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },

  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexDirection: "column",
  },
  gridWrapper: {
    height: "calc(100% - 40px)",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 90px)",
    },
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  dialogContainer: {
    "& .MuiPaper-root": {
      padding: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
    },
  },
  dialogHead: {
    ...theme.typography.heading24,
    fontWeight: "700",
    margin: 0,
    textAlign: "center",
    "& span": {
      color: theme.palette.yellow,
    },
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.heading20,
    },
  },
  dialogBodyFont: {
    ...theme.typography.body16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body14,
    },
  },
  button: {
    maxWidth: "250px !important",
  },
  continueButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  cancelButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    marginTop: "7px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  remainingContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& h2": {
      ...theme.typography.body20,

      margin: "10px 0",
    },
    "& button": {
      marginLeft: "25px",
      maxWidth: "200px !important",
      background: `${theme.palette.lightGreen} !important`,
      color: `${theme.palette.white} !important`,
      fontWeight: "400 !important",
    },
  },
}));

const UploadMedia = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { uploadLoading, loading, uploadedMedia, faces } = useSelector(
    (state) => state.Uploads
  );
  const { activeSubscriptionDetails } = useSelector(
    (state) => state.Subscriptions
  );
  const {
    loading: searchLoading,
    error: searchError,
    errorMessage: searchErrorMessage,
    errorTitle,
    isSubscriptionInActive,
  } = useSelector((State) => State.matches);

  const [faceId, setFaceId] = useState(0);
  const [error, setError] = useState("");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const openDeleteDialog = (data) => {
    setShowDeleteModal(data);
  };

  const closeDeleteDialog = () => {
    setShowDeleteModal(false);
  };

  const handleUploadImage = (image) => {
    const supportedFileTypes = ['jpeg', 'jpg','png','webg','heic'];
    const filename = image.name;
    const fileType = filename.substring(
      filename.lastIndexOf(".") + 1,
      filename.length
    );

    if (!supportedFileTypes.includes(fileType.toLowerCase())) {
      toast.error("File not supported.");
      return;
    }

    const formData = new FormData();
    formData.append("image", image);
    dispatch(uploadAssetAction(formData));
  };

  const getData = () => uploadedMedia;

  const handleNextPic = () => {
    if (faceId < faces.length - 1) {
      setFaceId((current) => current + 1);
      return;
    }
    setError(" Image does not contain any more faces.");
  };

  const handleYes = () => {
    sessionStorage.setItem("uploads", uploadedMedia.length + 1);

    dispatch(saveAssetAction(faces[faceId].asset_id, false, false));
  };

  const handleSearch = (element) => {
    sessionStorage.setItem("recent_search", element._id);
    dispatch(
      searchAssetAction(element._id, () => {
        dispatch(getActiveSubscriptionAction());
        navigate("/search");
      })
    );
  };

  const paymentSuccessCallback = () => {
    dispatch(
      searchAssetAction(sessionStorage.getItem("recent_search"), () => {
        navigate("/search");
      })
    );
    sessionStorage.removeItem("recent_search");
  };

  const closeUpgradeDialog = () => {
    dispatch(clearMatchErrorAction());
  };

  const handleSubscribeModalConfirmation = () => {
    if (isSubscriptionInActive) {
      setShowUpgradeModal(true);
    }
    closeUpgradeDialog();
  };

  const handleUploadAgain = () => {
    dispatch(clearfacesAction());
    setError("");
  };

  const handleDelete = () => {
    dispatch(deleteAssetAction(showDeleteModal._id));
    setShowDeleteModal(false);
  };

  useEffect(() => {
    dispatch(getUploadedImagesAction());
  }, [dispatch]);

  return (
    <div className={classes.uploadMediaContainer}>
      <ConfirmationDialog
        open={searchError}
        heading={errorTitle}
        body={searchErrorMessage}
        confirmButtonName={isSubscriptionInActive ? "Subscribe" : "Okay"}
        cancelButtonName={isSubscriptionInActive ? "Cancel" : undefined}
        onClose={closeUpgradeDialog}
        handleConfirmation={handleSubscribeModalConfirmation}
      />
      <ConfirmationDialog
        open={showDeleteModal}
        heading="Delete Asset"
        body="Are you sure want to delete this asset ?"
        confirmButtonName="Yes"
        cancelButtonName="No"
        onClose={closeDeleteDialog}
        handleConfirmation={handleDelete}
      />
      <UpgradePlanDialog
        open={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
        successCallback={paymentSuccessCallback}
      />
      <div className={classes.contentContainer}>
        {error ? (
          <>
            <h2 className={classes.content}>{error}</h2>
            <CustomButton
              onClick={handleUploadAgain}
              className={classes.button}
              yellowFilled
            >
              Upload Again
            </CustomButton>
          </>
        ) : loading ? (
          <>
            <h2 className={classes.content}>
              One moment <br />
              while we process your image...
            </h2>
            <CustomSpinner2 />
          </>
        ) : (
          <>
            {faces.length > 0 ? (
              <FaceCheck
                faces={faces}
                faceId={faceId}
                handleyes={handleYes}
                handleNextPic={handleNextPic}
              />
            ) : uploadLoading || searchLoading ? (
              <div className={classes.loader}>
                <CustomSpinner />
              </div>
            ) : (
              <>
                <div className={classes.remainingContainer}>
                  <h2>
                    Remaining Searches :{" "}
                    {activeSubscriptionDetails.availbleSearches -
                      activeSubscriptionDetails.userSearches}{" "}
                    / {activeSubscriptionDetails.availbleSearches}
                  </h2>
                  {(activeSubscriptionDetails.userSearches * 100) /
                    activeSubscriptionDetails.availbleSearches >=
                    70 && (
                    <CustomButton onClick={() => navigate("/account/billing")}>
                      Upgrade now
                    </CustomButton>
                  )}
                </div>
                {getData()?.length > 0 ? (
                  <div className={classes.gridWrapper}>
                    <Grid
                      container
                      spacing={2}
                      className={classes.bodyContainer}
                    >
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <UploadCard handleUploadImage={handleUploadImage} />
                      </Grid>
                      {getData().map((element, key) => (
                        <Grid item xs={12} sm={6} md={3} lg={3} key={key}>
                          <UploadMediaCard
                            image={element.url}
                            date={element.added_on}
                            handleSearch={() => handleSearch(element)}
                            onDelete={() => openDeleteDialog(element)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ) : (
                  <UploadBox handleUploadImage={handleUploadImage} />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UploadMedia;
