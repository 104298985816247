import { makeStyles } from "@mui/styles";
import React from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getFacesAction } from "../../Store/Matches/actions";
import CustomSpinner2 from "../UI/CustomSpinner2.jsx";

const useStyles = makeStyles((theme) => ({
  stepUploadContainer: {
    "& h2": {
      textAlign: "center",
      ...theme.typography.heading36,
      margin: 0,
      marginBottom: "25px",
    },
    "& p": {
      textAlign: "center",
      ...theme.typography.body24,
      margin: 0,
      marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      "& h2": {
        marginBottom: "5px",
        fontSize: "24px",
        lineHeight: "34px",
      },
      "& p": {
        marginBottom: "20px",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },

  uploadContainer: {
    background: theme.palette.lightGreen2,
    marginTop: "60px",
    width: "100%",
  },
  uploadWrap: {
    border: "1px dashed #799995",
    borderRadius: "5px",
    height: "250px",
    cursor: "pointer",
  },
  inner: {
    padding: "15px",
  },
  input: {
    display: "none",
  },
  text: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",

    "& h3": {
      ...theme.typography.body24,
      color: theme.palette.lightGreen,
    },
    "& svg": {
      fontSize: "30px",
      color: theme.palette.lightGreen,
      marginRight: "10px",
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& h2": {
      marginTop: "20px",
      marginBottom: "30px",
    },
  },
}));

const StepUpload = ({ setProgress, onNext, setUploadedImage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.matches);

  const onUpload = (event) => {

    const supportedFileTypes = ['jpeg', 'jpg','png','webg','heic'];
    const filename = event.target.files[0].name;
    const fileType = filename.substring(
      filename.lastIndexOf(".") + 1,
      filename.length
    );

    if (!supportedFileTypes.includes(fileType.toLowerCase())) {
      toast.error("File not supported.");
      return;
    }
    setUploadedImage(event.target.files[0]);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    dispatch(
      getFacesAction(
        formData,
        () => {
          onNext();
        },
        () => {
          toast.error(
            "We couldn't find your face. Please upload another image."
          );
          setProgress({ key: 3, matchFound: false });
        }
      )
    );
  };

  return (
    <div className={classes.stepUploadContainer}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <h2>
            One moment <br />
            while we process your image...
          </h2>
          <CustomSpinner2 />
        </div>
      ) : (
        <>
          <h2>Please upload a forward facing profile picture</h2>
          <p>
            Profile images will cover 98% of potential matches.
            <br /> These images are never stored or saved. Phone selfies are the
            perfect sized image.
          </p>

          <div className={classes.uploadContainer}>
            <input
              className={classes.input}
              type="file"
              accept="image/*"
              id="image-upload"
              name="myImage"
              onChange={onUpload}
            />
            <label htmlFor="image-upload">
              <div className={classes.inner}>
                <div className={classes.uploadWrap}>
                  <div className={classes.text}>
                    <MdOutlineFileUpload />
                    <h3>Upload File</h3>
                  </div>
                </div>
              </div>
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default StepUpload;
