import React from "react";
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CloseIcon from "../../Assets/Images/close_icon.png";

const useStyles = makeStyles((theme) => ({
  popperContainer: {
    padding: "30px 20px",
  },
  closeIcon: {
    position: "absolute",
    right: 5,
    top: 5,
    cursor:"pointer",
    [theme.breakpoints.down("sm")]: {
      height: "30px",
      width: "30px",
    },
  },
}));

const CustomPopup = ({ children, noPadding, withClose, imgID, ...rest }) => {
  const classes = useStyles();

  return (
    <Dialog {...rest}>
      {withClose && (
        <img
          src={CloseIcon}
          className={classes.closeIcon}
          onClick={rest.onClose}
          id={imgID}
        />
      )}
      {noPadding ? (
        children
      ) : (
        <div className={classes.popperContainer}>{children}</div>
      )}
    </Dialog>
  );
};

export default CustomPopup;
