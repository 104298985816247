import { Avatar, Grid, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import {
  MdLogout,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineSettings,
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { getInitials } from "../../Helpers/functions";
import Inbox from "../../Assets/Images/ic_dmca_inbox.svg";
import Alerts from "../../Assets/Images/ic_alerts.svg";
import Matches from "../../Assets/Images/ic_matches.svg";
import Search from "../../Assets/Images/ic_searched_results.svg";
import TakeDownRequest from "../../Assets/Images/ic_takedown_requests.svg";
import UploadMedia from "../../Assets/Images/ic_uploaded_media.svg";
import Profile from "../../Assets/Images/ic_profile.svg";
import Security from "../../Assets/Images/ic_security.svg";
import Billing from "../../Assets/Images/ic_billing.svg";
import Dashboard from "../../Assets/Images/dashboard_icon.png";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    margin: "8px 4px",
    padding: "9px 20px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.black,
    "&.active": {
      background: "#EEEEEE",
      "& .MuiTypography-root": {
        fontWeight: 700,
      },
    },
    [theme.breakpoints.down("sm")]: {
      borderTop: `1px solid ${theme.palette.grey4}`,
      margin: 0,
      padding: "17px 24px",
    },
  },
  accountSettingsContainer: {
    "&.MuiGrid-root": {
      width: "100vw",
    },
    boxShadow: "0px 4px 20px rgb(0 0 0 / 5%)",
    padding: "10px",
    height: "56px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.black,
    "& h2": {
      margin: 0,
      marginLeft: "10px",
      marginRight: "20px",
      fontWeight: 700,
      lineHeight: "21px",
      fontSize: "18px",
      textAlign: "center",
    },
  },
  accountSettings: {
    justifyContent: "space-between",
    "& .accountSettingsSection1": {
      display: "flex",
      alignItems: "center",
    },
  },
  marginLeft: {
    marginLeft: "25px",
  },
  nameContainer: {
    margin: "12px 4px",
    padding: "12px 14px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
  },
  optionName: {
    marginLeft: "10px !Important",
  },
  optionHead: {
    marginLeft: "10px !Important",
    [theme.breakpoints.down("sm")]: {
      fontWeight: "500 !important",
      color: theme.palette.black,
    },
  },
  icon: {
    height: "30px",
    width: "30px",
  },

  avatar: {
    height: "35px !important",
    width: "35px !important",
    fontSize: "14px !important",
    backgroundColor: `${theme.palette.lightGreen} !important`,
    [theme.breakpoints.down("sm")]: {
      height: "50px !important",
      width: "50px !important",
    },
  },
  marginLeftAuto: {
    marginLeft: "auto",
  },
  mobileLogoutContianer: {
    marginTop: "auto",
    padding: "13px",
  },
  button: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    borderColor: `${theme.palette.black} !important`,
    "& svg": {
      marginRight: "5px",
    },
  },
  mobileOptionsContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const options = [
  {
    name: "Uploaded Media",
    icon: UploadMedia,
    link: "/uploaded-media",
  },
  {
    name: "Search Results",
    icon: Search,
    link: "/search",
  },
  {
    name: "Saved Matches",
    icon: Matches,
    link: "/matches",
  },
  {
    name: "Takedown Requests",
    icon: TakeDownRequest,
    link: "/takedown-requests",
  },
  {
    name: "Alerts",
    icon: Alerts,
    link: "/alerts",
  },
  {
    name: "Bring Your Own Url",
    icon: Inbox,
    link: "/bring-own-url",
  },
  {
    name: "DMCA Inbox",
    icon: Inbox,
    link: "/dmca-inbox",
    mobileOnly: true,
  },
];

const accountOptions = [
  {
    name: "Dashboard",
    icon: Dashboard,
    link: "/uploaded-media ",
  },
  {
    name: "Profile",
    icon: Profile,
    link: "/account/profile",
  },
  {
    name: "Security",
    icon: Security,
    link: "/account/security",
  },
];

const Options = ({ closeDrawer, userData, accountMenu }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [showAccountMenu, setShowAccountMenu] = useState(accountMenu || false);

  const activeLink = `/${
    pathname.split("/").length > 2
      ? `${pathname.split("/")[1]}/${pathname.split("/")[2]}`
      : pathname.split("/")[1]
  }`;
  const isAccountOptions = pathname.split("/")[1] === "account";

  const getOptions = () => {
    if (showAccountMenu) {
      return accountOptions
        .filter((e, i) => i !== 0)
        .map((e) => ({ ...e, showArrow: true }));
    }
    if (isAccountOptions && !isMobileScreen) {
      return accountOptions;
    }
    return options;
  };

  const toggleAccountMenu = () => {
    setShowAccountMenu(!showAccountMenu);
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <div className={isMobileScreen && classes.mobileOptionsContainer}>
      <div>
        {isMobileScreen ? (
          showAccountMenu ? (
            <Grid
              container
              className={classes.accountSettingsContainer}
              onClick={() => toggleAccountMenu()}
            >
              <Grid item xs={1}>
                {" "}
                <MdOutlineKeyboardArrowLeft className={classes.icon} />
              </Grid>
              <Grid item xs={10}>
                <h2>Account Settings</h2>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          ) : (
            <>
              <div className={classes.nameContainer}>
                <Avatar className={classes.avatar}>
                  {userData?.full_name
                    ? getInitials(userData.full_name)
                    : getInitials(userData?.email_address)}
                </Avatar>
                <Typography variant="body18" className={classes.optionHead}>
                  {userData?.full_name
                    ? userData.full_name
                    : userData?.email_address}
                </Typography>
              </div>
              <div
                className={clsx(classes.menuContainer, classes.accountSettings)}
                onClick={() => toggleAccountMenu()}
              >
                <div className="accountSettingsSection1">
                  <MdOutlineSettings className={classes.icon} />
                  <Typography variant="body16" className={classes.optionName}>
                    Account Settings
                  </Typography>
                </div>
                <MdOutlineKeyboardArrowRight
                  className={clsx(classes.icon, classes.marginLeft)}
                />
              </div>
            </>
          )
        ) : undefined}
        {getOptions().map((option, key) => (
          <div key={key}>
            {option.mobileOnly && !isMobileScreen ? undefined : (
              <div
                onClick={() => {
                  if (isMobileScreen) closeDrawer();
                }}
              >
                <Link
                  to={option.link}
                  className={clsx(
                    classes.menuContainer,
                    activeLink === option.link ? "active" : ""
                  )}
                >
                  <img
                    src={option.icon}
                    alt={option.name}
                    className={classes.icon}
                  />
                  <Typography variant="body16" className={classes.optionName}>
                    {option.name}
                  </Typography>
                  {option.showArrow && (
                    <MdOutlineKeyboardArrowRight
                      className={clsx(classes.icon, classes.marginLeftAuto)}
                    />
                  )}
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>

      {isMobileScreen && !showAccountMenu && (
        <div className={classes.mobileLogoutContianer}>
          <CustomButton className={classes.button} onClick={logout}>
            <MdLogout />
            Logout
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default Options;
