import * as actionTypes from "./actionTypes";

export const getUploadedImagesAction = (callback) => ({
  type: actionTypes.GET_UPLOADED_IMAGES,
  callback,
});

export const uploadAssetAction = (payload) => ({
  type: actionTypes.UPLOAD_ASSET,
  payload,
});

export const saveAssetAction = (
  resultID,
  onboarding,
  token,
  successCallback,
  failCallback
) => ({
  type: actionTypes.SAVE_ASSET,
  resultID,
  onboarding,
  token,
  successCallback,
  failCallback,
});

export const clearfacesAction = () => ({
  type: actionTypes.CLEAR_FACES,
});

export const deleteAssetAction = (id) => ({
  type: actionTypes.DELETE_ASSET,
  id,
});
