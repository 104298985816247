import { makeStyles } from "@mui/styles";
import { MdOutlineAdd } from "react-icons/md";
import React from "react";

const useStyles = makeStyles((theme) => ({
  addOwnUrlCardContainer: {
    background: theme.palette.lightGreen2,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
    height: "calc(100% - 28px)",
    cursor: "pointer",
    minHeight: "250px",
  },
  innerLine: {
    margin: "10px",
    height: "calc(100% - 20px)",
    border: `1px dashed ${theme.palette.lightGreen}`,
    borderRadius: "5px",
  },
  text: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",

    "& h3": {
      ...theme.typography.body24,
      color: theme.palette.lightGreen,
    },
    "& svg": {
      fontSize: "30px",
      color: theme.palette.lightGreen,
      marginRight: "10px",
    },
  },

  body: {
    position: "relative",
  },
}));

const AddNewUrlCard = ({ handleAddUrl }) => {
  const classes = useStyles();

  return (
    <div className={classes.addOwnUrlCardContainer} onClick={handleAddUrl}>
      <div className={classes.innerLine}>
        <div className={classes.text}>
          <MdOutlineAdd />
          <h3>Add Url</h3>
        </div>
      </div>
    </div>
  );
};

export default AddNewUrlCard;
