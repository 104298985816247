import React, { useMemo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
// import SignUp from "./Pages/SignUp/SignUp.jsx";
import Login from "./Pages/Login/Login.jsx";
import Confirmation from "./Pages/Confirmation/Confirmation.jsx";
import EmailVerification from "./Pages/EmailVerification/EmailVerification.jsx";
import authProtectedRoutes from "./Routes/routes";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword.jsx";
import ForgotPasswordConfirmation from "./Pages/ForgotPasswordConfirmation/ForgotPasswordConfirmation.jsx";
import CreateNewPassword from "./Pages/CreateNewPassword/CreateNewPassword.jsx";
import LoginBack from "./Pages/LoginBack/LoginBack.jsx";
import DashboardWrapper from "./Components/DashboardWrapper/DashboardWrapper.jsx";
import Onboarding from "./Pages/Onboarding/Onboarding.jsx";
import PaymentTesting from "./Pages/PaymentTesting/PaymentTesting.jsx";

const App = () => {
  const privateRoutes = useMemo(
    () =>
      authProtectedRoutes.map(
        ({ component: Component, path, noSideMenu, noMobileHead }, key) => (
          <Route
            path={path}
            key={key}
            element={
              !localStorage.getItem("token") ? (
                <Navigate to={{ pathname: "/login" }} />
              ) : (
                <DashboardWrapper
                  noSideMenu={noSideMenu}
                  noMobileHead={noMobileHead}
                >
                  <Component />
                </DashboardWrapper>
              )
            }
          />
        )
      ),
    []
  );

  if (
    window.location.href.includes("password-reset") &&
    localStorage.getItem("token")
  ) {
    localStorage.clear();
    window.location.reload();
  }
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {localStorage.getItem("token") ? (
        <Routes>
          {privateRoutes}
          <Route
            path="*"
            element={<Navigate to={{ pathname: "/uploaded-media" }} />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/verify/:token" element={<EmailVerification />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password/confirmation"
            element={<ForgotPasswordConfirmation />}
          />
          <Route
            path="/password-reset/:token"
            element={<CreateNewPassword />}
          />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/login-back" element={<LoginBack />} />
          <Route path="/payment-testing" element={<PaymentTesting />} />
          <Route path="*" element={<Navigate to={{ pathname: "/login" }} />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
