import * as actionTypes from "./actionTypes";

export const loginAction = (payload, navigate) => ({
  type: actionTypes.LOGIN,
  payload,
  navigate,
});

export const registerAction = (payload, navigate) => ({
  type: actionTypes.REGISTER,
  payload,
  navigate,
});

export const validateToken = (token) => ({
  type: actionTypes.VALIDATE_TOKEN,
  token,
});

export const resendTokenAction = (email) => ({
  type: actionTypes.RESEND_TOKEN,
  email,
});

export const resetAuthStatusAction = () => ({
  type: actionTypes.RESET_AUTH_STATUS,
});

export const forgotPasswordAction = (email, navigate, isAuth) => ({
  type: actionTypes.FORGOT_PASSWORD_REQUEST,
  email,
  navigate,
  isAuth,
});

export const resetPasswordAction = (credentials, navigate) => ({
  type: actionTypes.RESET_PASSWORD,
  payload: credentials,
  navigate,
});

export const onboardUserAction = (email, successCallback) => ({
  type: actionTypes.ONBOARD_USER,
  email,
  successCallback,
});

export const onboardStep2Action = (data, token, successCallback) => ({
  type: actionTypes.ONBOARD_USER_STEP_2,
  token,
  data,
  successCallback,
});

export const logoutAction = () => ({
  type: actionTypes.LOGOUT,
});
