import * as actionTypes from "./actionTypes";

export const getSubscriptionPlansAction = () => ({
  type: actionTypes.GET_SUBSCRIPTION_PLANS,
});

export const getActiveSubscriptionAction = () => ({
  type: actionTypes.GET_ACTIVE_SUBSCRIPTION,
});

export const getBillsAction = () => ({
  type: actionTypes.GET_BILLS,
});

export const upgradeSubscription = (planData, successCallback) => ({
  type: actionTypes.UPGRADE_SUBSCRIPTION,
  planData,
  successCallback,
});

export const cancelSubscriptionAction = () => ({
  type: actionTypes.CANCEL_SUBSCRIPTION,
});
