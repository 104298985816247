export const SET_UPLOADS_LOADING = "SET_UPLOADS_LOADING";
export const SET_UPLOADED_MEDIA_LOADING = "SET_UPLOADED_MEDIA_LOADING";

export const GET_UPLOADED_IMAGES = "GET_UPLOADED_IMAGES";
export const GET_UPLOADED_IMAGES_SUCCESS = "GET_UPLOADED_IMAGES_SUCCESS";
export const GET_UPLOADED_IMAGES_FAILURE = "GET_UPLOADED_IMAGES_FAILURE";

export const UPLOAD_ASSET = "UPLOAD_ASSET";
export const UPLOAD_ASSET_SUCCESS = "UPLOAD_ASSET_SUCCESS";
export const UPLOAD_ASSET_FAILURE = "UPLOAD_ASSET_FAILURE";

export const SAVE_ASSET = "SAVE_ASSET";
export const SAVE_ASSET_SUCCESS = "SAVE_ASSET_SUCCESS";
export const SAVE_ASSET_FAILURE = "SAVE_ASSET_FAILURE";

export const DELETE_ASSET = "DELETE_ASSET";
export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILURE = "DELETE_ASSET_FAILURE";

export const CLEAR_FACES = "CLEAR_FACES";
