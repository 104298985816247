import { makeStyles } from "@mui/styles";
import { MdOutlineLanguage } from "react-icons/md";
import React from "react";
import CustomButton from "../UI/CustomButton.jsx";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  takeDownCardContainer: {
    background: theme.palette.white,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  body: {
    position: "relative",
  },

  moreIcon: {
    fontSize: "30px",
    color: theme.palette.black,
  },
  userImg: {
    height: "400px",
    [theme.breakpoints.up(1700)]: {
      height: "600px",
    },
    width: "100%",
    objectFit: "contain",
  },
  websiteContainer: {
    position: "absolute",
    bottom: "0px",
    padding: "10px",
    width: "-webkit-fill-available",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  },
  website: {
    ...theme.typography.body16,
    color: theme.palette.white,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  globe: {
    marginRight: "5px",
    fontSize: "20px ",
  },
  buttonsContainer: {
    marginTop: "20px",
    "& button": {
      padding: "12px",
      boxShadow: "none",
      fontWeight: "400",
      fontSize: "16px",
      textAlign: "center",
    },
    "& h3": {
      textAlign: "center",
      color: theme.palette.lightGreen,
    },
  },
}));

const TakeDownCard = ({
  image,
  website,
  openConditionsModal,
  status,
  openModal,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.takeDownCardContainer}>
      <div className={classes.headContainer}></div>
      <div className={classes.body}>
        <img
          src={image || placeholder}
          alt="user-img"
          className={classes.userImg}
        />
        <div className={classes.websiteContainer}>
          <div className={classes.website} onClick={() => openModal()}>
            <MdOutlineLanguage className={classes.globe} /> {website}
          </div>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {status === "created" ? (
          <CustomButton onClick={() => openConditionsModal()}>
            Start Takedown Process
          </CustomButton>
        ) : status === "processed" ? (
          <h3>Take down in process.</h3>
        ) : (
          <h3>Taken down</h3>
        )}
      </div>
    </div>
  );
};

export default TakeDownCard;
