import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  clearMatchErrorAction,
  deleteSearchAction,
  getDashBoardSearch,
  saveMatchAction,
} from "../../Store/Matches/actions";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import SearchCard from "../../Components/Searches/SearchCard.jsx";
import WebsiteDialog from "../../Components/Common/WebsiteDialog.jsx";
import Filter from "../../Components/UI/Filter.jsx";
// import blackGroundImage from "../../Assets/Images/blurred_image.png";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    height: "100%",
    width: "100%",
  },
  bodyContainer: {
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  bodyBottom: {
    paddingBottom: "50px",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  additionalImagesContainer: {
    marginTop: "25px",
  },
  head: {
    ...theme.typography.heading32,
    fontWeight: 500,
    margin: 0,
    // marginBottom: "10px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      marginLeft: "15px",
      marginRight: "10px",
    },
  },
  para: {
    ...theme.typography.body20,
    margin: 0,
    marginBottom: "20px",
    marginLeft: "10px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginLeft: "15px",
      marginRight: "10px",
    },
  },
  divider: {
    width: "100%",
    marginTop: "5px",
    borderBottom: `1px solid ${theme.palette.grey5}`,
  },

  noSearchContianer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h2": {
      ...theme.typography.heading36,
      fontWeight: 500,
      margin: 0,
      marginBottom: "20px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
  },
}));

const Search = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const classes = useStyles();
  // const navigate = useNavigate();

  const { searches, loading, error, errorMessage } = useSelector(
    (State) => State.matches
  );

  const [websiteModal, setwebsiteModal] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState({
    image: false,
    video: false,
    websites: [],
  });

  const fromOnboarding = search?.split("?")[1]?.split("=")[0] === "onboarding";

  const getSearches = () => {
    if (fromOnboarding) {
      return JSON.parse(sessionStorage.getItem("searches")) || [];
    }
    return searches;
  };

  const filteredSearches = useMemo(
    () =>
      getSearches()
        .filter((e) => {
          if (filterOptions.image && filterOptions.video) {
            return true;
          }
          if (filterOptions.image) {
            return e.source_type === "image";
          }
          if (filterOptions.video) {
            return e.source_type === "video";
          }
          return true;
        })
        .filter((e) => {
          if (filterOptions.websites.length === 0) {
            return true;
          }
          return filterOptions.websites.includes(e.domain);
        }),
    [getSearches, filterOptions]
  );

  const openWebsiteModal = (e) => {
    setwebsiteModal(e);
  };

  const closeWebsiteModal = () => {
    setwebsiteModal(false);
  };

  const saveAsset = (e) => {
    dispatch(
      saveMatchAction(
        {
          asset_id: e.asset_id,
          source_id: e.source_id,
          face: e.face,
          frame: e.image,
          domain: e.domain,
          image_url: e.image_url,
          video_url: e.video_url,
          page_url: e.page_url,
          distance: e.distance,
          source_type: e.source_type,
          title: e.title,
          search_id: e.search_id,
        },
        fromOnboarding
      )
    );
  };

  const deleteSearch = (e) =>
    dispatch(
      deleteSearchAction(
        { source_id: e.source_id, face: e.face },
        fromOnboarding
      )
    );

  useEffect(() => {
    dispatch(getDashBoardSearch());

    return () => {
      dispatch(clearMatchErrorAction());
    };
  }, [dispatch]);

  return (
    <div className={classes.searchContainer}>
      <WebsiteDialog open={websiteModal} onClose={closeWebsiteModal} />
      {error ? (
        <div className={classes.noSearchContianer}>
          <h2>{errorMessage}</h2>
        </div>
      ) : (
        <>
          {loading ? (
            <div className={classes.loaderContainer}>
              <CustomSpinner />
            </div>
          ) : getSearches().length > 0 ? (
            <>
              <Filter
                setFilterOptions={setFilterOptions}
                websites={getSearches()
                  .map((item) => item.domain)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  )}
              />
              <h2 className={classes.head}>Your Results</h2>
              <p className={classes.para}>
                We found{" "}
                {filteredSearches.filter((e) => e.distance <= 450).length}{" "}
                results for you
              </p>
              <Grid container spacing={2} className={classes.bodyContainer}>
                {filteredSearches
                  .filter((e) => e.distance <= 400)
                  .map((e, key) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={key}>
                      <SearchCard
                        image={
                          e.source_type === "image"
                            ? e.image_url
                            : e.frameSignedUrl
                        }
                        sourceType={e.source_type}
                        website={e.domain}
                        distance={e.distance}
                        onSave={() => saveAsset(e)}
                        openModal={() => openWebsiteModal(e)}
                        handleNotMe={() => deleteSearch(e)}
                      />
                    </Grid>
                  ))}
              </Grid>
              <div className={classes.bodyBottom}> 
              {filteredSearches.filter((e) => e.distance > 250).length > 0 && (
                <>
                  <div className={classes.divider} />
                  <div className={classes.additionalImagesContainer}>
                    <h2 className={classes.head}>Additional Results</h2>
                    <p className={classes.para}>
                      These images look a lot like you but we are less confident
                      that it is you. We’re showing you in case these outliers
                      do in fact have your face; it’s important to us that
                      you’re entirely covered.
                    </p>
                    <Grid
                      container
                      spacing={2}
                      className={classes.bodyContainer}
                    >
                      {filteredSearches
                        .filter((e) => e.distance > 400)
                        .map((e, key) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} key={key}>
                            <SearchCard
                              image={
                                e.source_type === "image"
                                  ? e.image_url
                                  : e.frameSignedUrl
                              }
                              sourceType={e.source_type}
                              website={e.domain}
                              distance={e.distance}
                              onSave={() => saveAsset(e)}
                              openModal={() => openWebsiteModal(e)}
                              handleNotMe={() => deleteSearch(e)}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </div>
                </>
              )}
              </div>
            </>
          ) : (
            <div className={classes.noSearchContianer}>
              <h2>Please search from one of your uploaded assets.</h2>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Search;
