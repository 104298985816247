import { makeStyles } from "@mui/styles";
import { MdOutlineLanguage, MdAdd } from "react-icons/md";
import React from "react";
// import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import CustomButton from "../UI/CustomButton.jsx";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  matchesCardContainer: {
    background: theme.palette.white,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
  },
  headContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  head: {
    ...theme.typography.heading18,
    fontWeight: 700,
    margin: 0,
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
  },
  body: {
    position: "relative",
  },
  helpIcon: {
    fontSize: "20px",
    color: theme.palette.lightGreen,
  },
  moreIcon: {
    fontSize: "30px",
    color: theme.palette.black,
  },
  userImg: {
    height: "400px",
    [theme.breakpoints.up(1700)]: {
      height: "600px",
    },
    width: "100%",
    objectFit: "contain",
  },
  websiteContainer: {
    position: "absolute",
    bottom: "0px",
    padding: "10px",
    width: "-webkit-fill-available",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  },
  website: {
    ...theme.typography.body16,
    color: theme.palette.white,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  globe: {
    marginRight: "5px",
    fontSize: "20px ",
  },
  buttonsContainer: {
    marginTop: "20px",
    "& button": {
      width: "100%",
      background: theme.palette.white,
      color: theme.palette.black,
      textTransform: "none",
      padding: "12px",
      border: `1px solid ${theme.palette.grey3}`,
      boxShadow: "none",
      "&:hover": {
        background: theme.palette.white,
      },
    },
    "& h3": {
      textAlign: "center",
      color: theme.palette.lightGreen,
    },
  },
  btnRow1: {
    display: "flex",
    marginBottom: "10px",
    "& button:first-child": {
      marginRight: "10px",
    },
  },
}));

const MatchesCard = ({
  image,
  website,
  openModal,
  onTakeDown,
  distance,
  handleDelete,
  takedownId,
  sourceType,
}) => {
  const classes = useStyles();

  const getMatchType = () => {
    if (distance <= 100) return "100% confident";
    if (distance <= 200) return "95% confident";
    if (distance <= 300) return "90% confident";
    if (distance <= 400) return "80% confident";
    if (distance <= 500) return "75% confident";
    if (distance <= 600) return "70% confident";
    if (distance <= 700) return "65% confident";

    return "60% confident";
  };

  return (
    <div className={classes.matchesCardContainer}>
      <div className={classes.headContainer}>
        <h3 className={classes.head}>
          {getMatchType()}
          {/* <Tooltip
            disableFocusListener
            arrow
            placement="top"
            title="A close match is a result that has a high probability of being you. "
          >
            <IconButton>
              <MdHelpOutline className={classes.helpIcon} />
            </IconButton>
          </Tooltip> */}
        </h3>
        <h3 className={classes.head}>{sourceType}</h3>
      </div>
      <div className={classes.body}>
        <img
          src={image || placeholder}
          alt="user-img"
          className={classes.userImg}
        />
        <div className={classes.websiteContainer}>
          <div className={classes.website} onClick={() => openModal()}>
            <MdOutlineLanguage className={classes.globe} /> {website}
          </div>
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {takedownId ? (
          <h3>Takedown requested.</h3>
        ) : (
          <div className={classes.btnRow1}>
            <CustomButton yellow variant="contained" onClick={onTakeDown}>
              <MdAdd />
              Takedown
            </CustomButton>
            <Button variant="contained" onClick={handleDelete}>
              Not me
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchesCard;
