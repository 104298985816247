import { Checkbox, FormControlLabel, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Image from "../../Assets/Images/img1.jpg";
import { plans } from "../../constants";
import CustomButton from "../UI/CustomButton.jsx";
import CustomPopup from "../UI/CustomPopup.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      maxWidth: "100vw",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
    },
  },
  upgradeModalContainer: {
    display: "flex",
  },
  upgradeRightContainer: {
    flex: 1,
    overflowY: "hidden",

    width: "450px",
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "450px",
    },
  },
  upgradeLeftContainerPadding: {
    padding: "30px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: "24px 14px",
    },
  },
  upgradeLeftContainer: {
    minWidth: "300px",
    maxWidth: "450px",
    flex: 1,
    "& h2": {
      ...theme.typography.heading36,
      fontWeight: 500,
      margin: 0,
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  checkBoxContainer: {
    margin: "15px 0",

    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
      "& .MuiButtonBase-root": {
        "&.Mui-disabled": {
          color: theme.palette.grey6,
        },
      },
      "& .MuiTypography-root": {
        "&.Mui-disabled": {
          color: `${theme.palette.grey2} !important`,
        },
      },

      "& p": {
        ...theme.typography.heading24,
        marginTop: "9px",
        fontWeight: 700,
        lineHeight: "24px",
        margin: 0,
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
        "& span": {
          ...theme.typography.body16,
          [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
          },
        },
      },
    },
  },
  checkBoxInfo: {
    ...theme.typography.body18,
    marginTop: "9px",
    margin: 0,
    maxWidth: "390px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  infoActive: {
    color: theme.palette.grey2,
  },
  activePlanChip: {
    padding: "5px 7px !important",
    borderRadius: "5px !important",
    backgroundColor: `${theme.palette.lightGreen} !important`,
    "& .MuiChip-label": {
      padding: "0 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: theme.palette.white,
    },
  },
  notEligblePlanChip: {
    padding: "5px 7px !important",
    borderRadius: "5px !important",
    backgroundColor: `${theme.palette.grey2} !important`,
    "& .MuiChip-label": {
      padding: "0 !important",
      fontSize: "14px !important",
      lineHeight: "16px !important",
      color: theme.palette.white,
    },
  },
}));

const PickPlanDialog = ({ open, onClose, selectedPlan }) => {
  const classes = useStyles();

  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [checked, setChecked] = useState(0);

  useEffect(() => {
    const index = plans.findIndex((plan) => plan.name === selectedPlan.name);
    setChecked(index + 1);
  }, []);

  const handleCheck = (value) => {
    setChecked(value);
  };

  const handleClick = () => {
    onClose(plans[checked - 1]);
  };

  return (
    <CustomPopup
      noPadding
      open={Boolean(open)}
      onClose={onClose}
      className={classes.root}
      withClose={!isMobileScreen}
      imgID="popup-close"
    >
      <div className={classes.upgradeModalContainer}>
        <div className={classes.upgradeLeftContainer}>
          <div className={classes.upgradeLeftContainerPadding}>
            <h2>{"Pick Plan"}</h2>
            {plans.map((plan, i) => (
              <CheckBoxComponent
                details={plan.descriptionTxt}
                price={`$${plan.price}/month`}
                name={plan.name}
                key={i}
                checked={checked === i + 1}
                onChange={() => handleCheck(i + 1)}
                // activePlan={activeSubscription === plan.gateway_identifier}
                //   notEligible={
                //     (reason === "takedown" || reason === "savedMatches") &&
                //     plan.gateway_identifier === "loti-basic"
                //   }
              />
            ))}

            <CustomButton yellowFilled onClick={handleClick}>
              Pick {plans[checked - 1]?.name}
            </CustomButton>
          </div>
        </div>

        {!isMobileScreen && (
          <div className={classes.upgradeRightContainer}>
            <img src={Image} />
          </div>
        )}
      </div>
    </CustomPopup>
  );
};

export default PickPlanDialog;

const CheckBoxComponent = ({
  name,
  price,
  details,
  checked,
  onChange,
  activePlan,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.checkBoxContainer}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: 28 },
              color: "#408266",
              "&.Mui-checked": {
                color: "#408266",
              },
            }}
          />
        }
        label={
          <p>
            {name} <br />
            <span>{price}</span>
          </p>
        }
      />
      <p
        className={clsx(
          classes.checkBoxInfo,
          activePlan ? classes.infoActive : ""
        )}
      >
        {details}
      </p>
    </div>
  );
};
