import { makeStyles } from "@mui/styles";
import Moment from "moment";
import React from "react";
// import { IconButton } from "@mui/material";
import { MdSearch } from "react-icons/md";
import CustomButton from "../UI/CustomButton.jsx";
import CustomSpinner from "../UI/CustomSpinner.jsx";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  UploadMediaCardContainer: {
    background: theme.palette.white,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
  },

  saveButton: {
    marginTop: "10px !important",
    padding: "12px !important",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& h3": {
      ...theme.typography.body16,
      margin: 0,
      "& span": {
        fontWeight: 700,
      },
    },
  },
  iconButtonContainer: {
    padding: "0 !important",
  },
  icon: {
    fontSize: "18px",
    color: theme.palette.black,
  },
  userImg: {
    height: "250px",
    [theme.breakpoints.up(1700)]: {
      height: "600px",
    },
    width: "100%",
    objectFit: "cover",
  },
}));

const UploadMediaCard = ({
  image,
  handleSearch,
  date,
  searchLoading,
  // onDelete,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.UploadMediaCardContainer}>
      <div className={classes.head}>
        <h3>
          Date: <span>{Moment(date).format("MMM D, YYYY")}</span>{" "}
        </h3>
        {/* <IconButton onClick={onDelete} className={classes.iconButtonContainer}>
          <MdDeleteOutline className={classes.icon} />
        </IconButton> */}
      </div>
      <img
        src={image || placeholder}
        alt="user-img"
        className={classes.userImg}
      />
      {searchLoading ? (
        <CustomSpinner center />
      ) : (
        <CustomButton
          className={classes.saveButton}
          yellow
          onClick={handleSearch}
        >
          <MdSearch /> Search
        </CustomButton>
      )}
    </div>
  );
};

export default UploadMediaCard;
