import { Typography, Button, Menu } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
// import { MdOutlineFilter } from "react-icons/md";
import FilterIcon from "../../Assets/Images/filter_icon.png";
import MatchesFilter from "../Common/MatchesFilter.jsx";

const useStyles = makeStyles(() => ({
  filtersContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    "& button": {
      textTransform: "none",
      color: "inherit",
    },

    "& span": {
      marginRight: "5px",
    },

    "& img": {
      height: "25px",
      width: "25px",
    },
  },
}));

const Filter = ({ setFilterOptions, websites }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filter, setFilter] = React.useState({
    image: false,
    video: false,
    websites: [],
  });
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = () => {
    setFilterOptions(filter);
    handleClose();
  };

  const handleClear = () => {
    setFilter({
      image: false,
      video: false,
      websites: [],
    });
  };

  return (
    <div className={classes.filtersContainer}>
      <Button
        onClick={handleClick}
        aria-controls={open ? "filters" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Typography variant="body18">Fliters</Typography>{" "}
        <img src={FilterIcon} alt="filter_icon" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="filters"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MatchesFilter
          filterOptions={filter}
          setFilterOptions={setFilter}
          websites={websites}
          handleClear={handleClear}
          handleFilter={handleFilter}
        />
      </Menu>{" "}
    </div>
  );
};

export default Filter;
