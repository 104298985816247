import { Avatar, Box, Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdOutlineMailOutline, MdOutlineMenu, MdSearch } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getInitials } from "../../Helpers/functions";
import Options from "./Options.jsx";

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.white,
    bottom: "0",
    width: "100%",
    boxShadow: "0px -4px 10px rgba(0, 0, 0, 0.04)",
    zIndex: "100",
  },
  subContainer: {
    display: "flex",
    padding: "8px 15px",
    justifyContent: "space-between",
  },
  itemBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "30px",
      color: theme.palette.grey2,
    },
    "& p": {
      color: theme.palette.grey2,
      ...theme.typography.body14,
      margin: 0,
      textAlign: "center",
    },
  },
  active: {
    "& svg": {
      color: theme.palette.lightGreen,
    },
    "& p": {
      color: theme.palette.black,
      fontWeight: 500,
    },
  },
  avatar: {
    height: "30px !important",
    width: "30px !important",
    fontSize: "12px !important",
    backgroundColor: `${theme.palette.lightGreen} !important`,
  },
  drawer: {
    zIndex: "99 !important",
  },
  box: {
    height: "calc(100vh - 62px)",
    position: "relative",
    overflowY: "auto",
  },
}));

const BottomNavCss = {
    position: "absolute",
}
const BottomNavigation = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { userData } = useSelector((state) => state.user);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [accountMenuDrawer, setAccountMenuDrawer] = React.useState(false);

  const activeLink = `/${
    pathname.split("/").length > 2
      ? `${pathname.split("/")[1]}/${pathname.split("/")[2]}`
      : pathname.split("/")[1]
  }`;

  const menuItems = [
    {
      name: "Menu",
      Icon: <MdOutlineMenu />,
      onClick: () => {
        setAccountMenuDrawer(false);
        setDrawerOpen(true);
      },
    },
    {
      name: "Search",
      Icon: <MdSearch />,
      link: "/search",
    },
    {
      name: "Upload",
      Icon: <AiOutlineCloudUpload />,
      link: "/uploaded-media",
    },
    {
      name: "Inbox",
      Icon: <MdOutlineMailOutline />,
      link: "/dmca-inbox",
    },
    {
      name: "Account",
      Icon: (
        <Avatar className={classes.avatar}>
          {userData?.full_name
            ? getInitials(userData.full_name)
            : getInitials(userData?.email_address)}
        </Avatar>
      ),
      onClick: () => {
        setAccountMenuDrawer(true);
        setDrawerOpen(true);
      },
    },
  ];

  const toggleDrawer = (openval) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(openval);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const handleNavigate = (element) => {
    if (element.onClick) {
      element.onClick();
      return;
    }
    navigate(element.link);
    closeDrawer();
  };

  return (
    <div className={classes.container} style={
      !drawerOpen?BottomNavCss:null
}>
      <div className={classes.subContainer}>
        {menuItems.map((element, i) => (
          <div
            className={clsx(
              classes.itemBox,
              activeLink === element.link ? classes.active : ""
            )}
            key={i}
            onClick={() => {
              handleNavigate(element);
            }}
          >
            {element.Icon}
            <p>{element.name}</p>
          </div>
        ))}
      </div>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className={classes.drawer}
      >
        <Box className={classes.box} onKeyDown={toggleDrawer(false)}>
          <Options
            closeDrawer={() => closeDrawer()}
            userData={userData}
            accountMenu={accountMenuDrawer}
          />
        </Box>
      </Drawer>
    </div>
  );
};

export default BottomNavigation;
