import { makeStyles } from "@mui/styles";
import { MdOutlineLanguage } from "react-icons/md";
import React from "react";
import placeholder from "../../Assets/Images/loti-placeholder.jpg";

const useStyles = makeStyles((theme) => ({
  addOwnUrlCardContainer: {
    background: theme.palette.white,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
    "& h3": {
      textAlign: "center",
      color: theme.palette.lightGreen,
    },
  },

  body: {
    position: "relative",
  },

  userImg: {
    height: "250px",
    [theme.breakpoints.up(1700)]: {
      height: "600px",
    },
    width: "100%",
    objectFit: "contain",
  },
  websiteContainer: {
    position: "absolute",
    bottom: "0px",
    padding: "10px",
    width: "-webkit-fill-available",
    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
  },
  website: {
    ...theme.typography.body16,
    color: theme.palette.white,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  globe: {
    marginRight: "5px",
    fontSize: "20px ",
  },
  button: {
    marginTop: "10px !important",
  },
}));

const AddOwnUrlCard = ({ image, domain, status, handleWebsiteOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.addOwnUrlCardContainer}>
      <div className={classes.body}>
        <img
          src={image || placeholder}
          alt="user-img"
          className={classes.userImg}
        />
        <div className={classes.websiteContainer}>
          <div className={classes.website} onClick={() => handleWebsiteOpen()}>
            <MdOutlineLanguage className={classes.globe} /> {domain}
          </div>
        </div>
      </div>
      <h3>{status}</h3>
    </div>
  );
};

export default AddOwnUrlCard;
