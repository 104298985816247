import * as actionTypes from "./actionTypes";

export const getOwnUrlsAction = () => ({
  type: actionTypes.GET_OWN_URLS,
});

export const addOwnUrlsAction = (payload) => ({
  type: actionTypes.ADD_OWN_URL,
  payload,
});
