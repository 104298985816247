import { put, all, takeLatest } from "redux-saga/effects";
import { v4 } from "uuid";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../Axios/Axios";

// function* getMatchesSaga({ reqId, token, successCallback, failCallback }) {
//   try {
//     yield put({ type: actionTypes.SET_MATCHES_LOADING });
//     const Response = yield API.post(
//       "/asset/request/neighbours",
//       { request_id: reqId },
//       {
//         headers: {
//           Authorization: `Bearer ${token || localStorage.getItem("token")}`,
//         },
//       }
//     );
//     const { data } = Response;
//     if (Response.status === 200) {
//       yield put({
//         type: actionTypes.GET_MATCHES_SUCCESS,
//         payload: data.data.faces,
//       });
//       if (successCallback) {
//         successCallback();
//       }
//     } else {
//       yield put({
//         type: actionTypes.GET_MATCHES_FAILURE,
//         payload: "No matches found",
//       });

//       if (failCallback) {
//         failCallback();
//       }
//     }
//   } catch (error) {
//     yield put({
//       type: actionTypes.GET_MATCHES_FAILURE,
//       payload:
//         error?.response?.data?.message || "Unable to get matches information",
//     });
//     if (failCallback) {
//       failCallback();
//     }
//   }
// }

function* getFacesSaga({ formData, successCallback, failCallback }) {
  try {
    yield put({ type: actionTypes.SET_MATCHES_LOADING });

    const onboardingId = v4();
    sessionStorage.setItem("onboardingId", onboardingId);

    const { data } = yield API.post("/asset/onboard/face", formData, {
      headers: { "onboarding-id": onboardingId },
    });

    if (data) {
      yield put({
        type: actionTypes.GET_FACES_SUCCESS,
        payload: data.data.faces,
      });
      successCallback();
    } else {
      yield put({
        type: actionTypes.GET_FACES_FAILURE,
        payload: "Unable to get matches",
      });
      failCallback();
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_FACES_FAILURE,
      payload:
        error?.response?.data?.message || "Unable to get matches information",
    });
    failCallback();
  }
}

function* getDashBoardMatchesSaga() {
  try {
    yield put({ type: actionTypes.SET_MATCHES_LOADING });
    const { data } = yield API.get("/asset/matches");
    if (data.success) {
      yield put({
        type: actionTypes.GET_DASHBOARD_MATCHES_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_DASHBOARD_MATCHES_FAILURE,
        payload: "Unable to get matches",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_MATCHES_FAILURE,
      payload:
        error?.response?.data?.message || "Unable to get matches information",
    });
  }
}

// function* getfacesDashboardSaga({ formData, successCallback, failCallback }) {
//   try {
//     yield put({ type: actionTypes.SET_MATCHES_LOADING });

//     const { data } = yield API.post("/asset/request/face", formData);

//     if (data) {
//       yield put({
//         type: actionTypes.GET_FACES_DASHBOARD_SUCCESS,
//         payload: data.data.faces,
//       });
//       successCallback();
//     } else {
//       yield put({
//         type: actionTypes.GET_FACES_DASHBOARD_FAILURE,
//         payload: "Unable to get matches",
//       });
//       failCallback();
//     }
//   } catch (error) {
//     yield put({
//       type: actionTypes.GET_FACES_DASHBOARD_FAILURE,
//       payload:
//         error?.response?.data?.message || "Unable to get matches information",
//     });
//     failCallback();
//   }
// }

function* deleteSearchSaga({ payload, fromOnboarding }) {
  try {
    yield put({ type: actionTypes.SET_MATCHES_LOADING });
    const { data } = yield API.post("/asset/not-me", payload);

    if (data.success) {
      yield put({ type: actionTypes.DELETE_SEARCH_SUCCESS, payload });
      if (fromOnboarding) {
        const tempResult = JSON.parse(sessionStorage.getItem("searches"));

        sessionStorage.setItem(
          "searches",
          JSON.stringify(tempResult.filter((e) => e.face !== payload.face))
        );
      }
      toast.success("Successfully removed the asset.");
    } else {
      yield put({
        type: actionTypes.DELETE_SEARCH_FAILURE,
        payload: "Unable to remove result.",
      });
      toast.error("Unable to remove result.");
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_SEARCH_FAILURE,
      payload: error?.response?.data?.message || "Unable to remove result.",
    });
    toast.error("Unable to remove result.");
  }
}

function* saveMatchSaga({ payload, fromOnboarding }) {
  try {
    const { data } = yield API.post("/asset/save-match", payload);

    if (data.success) {
      yield put({ type: actionTypes.SAVE_MATCH_SUCCESS, payload });
      if (fromOnboarding) {
        const tempResult = JSON.parse(sessionStorage.getItem("searches"));

        sessionStorage.setItem(
          "searches",
          JSON.stringify(tempResult.filter((e) => e.face !== payload.face))
        );
      }
      toast.success("Successfully saved the asset, check the saved matches.");
    } else {
      yield put({
        type: actionTypes.SAVE_MATCH_FAILURE,
        payload: "Unable to save the result.",
      });
      toast.error("Unable to save the result.");
    }
  } catch (error) {
    yield put({
      type: actionTypes.SAVE_MATCH_FAILURE,
      payload: error?.response?.data?.message || "Unable to save the result.",
    });
    toast.error("Unable to save the result.");
  }
}

function* getDashboardSearch({
  onboarding,
  token,
  successCallback,
  failCallback,
}) {
  try {
    yield put({ type: actionTypes.SET_MATCHES_LOADING });
    const { data } = yield API.get("/asset/searches", {
      headers: {
        Authorization: `Bearer ${
          onboarding ? token : localStorage.getItem("token")
        }`,
      },
    });
    if (data.success) {
      if (onboarding) {
        yield put({
          type: actionTypes.GET_DASHBOARD_SEARCH_SUCCESS,
          payload: data.data.finalResultSorted,
          exactMatches: data.data.exact_matches,
        });
      } else {
        yield put({
          type: actionTypes.GET_DASHBOARD_SEARCH_SUCCESS,
          payload: data.data.finalResultSorted,
        });
      }

      if (successCallback) successCallback(data?.data);
    } else {
      yield put({
        type: actionTypes.GET_DASHBOARD_SEARCH_FAILURE,
        payload: "Unable to get search results.",
      });
      if (failCallback) failCallback();
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_SEARCH_FAILURE,
      payload: error?.response?.data?.message?.message
        ? error?.response?.data?.message?.message
        : error?.response?.data?.message || "Unable to get search results.",
      isSubscriptionInActive:
        error?.response?.data?.statusCode === 403 &&
        !error?.response?.data?.message?.subscribed,
    });
    if (failCallback) failCallback();
  }
}

function* searchAssetSaga({
  id,
  successCallback,
  failCallback,
  onboarding,
  token,
}) {
  try {
    yield put({ type: actionTypes.SET_MATCHES_LOADING });
    const { data } = yield API.post(
      "/asset/search",
      {
        asset_id: id,
      },
      {
        headers: {
          Authorization: `Bearer ${
            onboarding ? token : localStorage.getItem("token")
          }`,
        },
      }
    );
    if (data.success) {
      yield put({
        type: actionTypes.SEARCH_ASSET_SUCCESS,
        payload: data.data,
        count: data.data.count,
      });
      // if (onboarding) {
      //   sessionStorage.setItem("searches", JSON.stringify(data.data.faces));
      // }
      if (successCallback) successCallback();
    } else {
      yield put({
        type: actionTypes.SEARCH_ASSET_FAILURE,
        payload: "Unable to get search for asset.",
      });
      if (failCallback) failCallback();
    }
  } catch (error) {
    yield put({
      type: actionTypes.SEARCH_ASSET_FAILURE,
      payload: error?.response?.data?.message?.message
        ? error?.response?.data?.message?.message
        : error?.response?.data?.message || "Unable to get search results.",
      title: error?.response?.data?.message?.title,
      isSubscriptionInActive:
        error?.response?.data?.statusCode === 403 &&
        !error?.response?.data?.message?.subscribed,
    });
    if (failCallback) failCallback();
  }
}

function* matchesSaga() {
  yield all([
    // yield takeLatest(actionTypes.GET_MATCHES, getMatchesSaga),
    yield takeLatest(actionTypes.GET_FACES, getFacesSaga),
    yield takeLatest(
      actionTypes.GET_DASHBOARD_MATCHES,
      getDashBoardMatchesSaga
    ),
    // yield takeLatest(actionTypes.GET_FACES_DASHBOARD, getfacesDashboardSaga),
    yield takeLatest(actionTypes.DELETE_SEARCH, deleteSearchSaga),
    yield takeLatest(actionTypes.SAVE_MATCH, saveMatchSaga),
    yield takeLatest(actionTypes.GET_DASHBOARD_SEARCH, getDashboardSearch),
    yield takeLatest(actionTypes.SEARCH_ASSET, searchAssetSaga),
  ]);
}

export default matchesSaga;
