import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../UI/CustomButton.jsx";
import CustomPopup from "../UI/CustomPopup.jsx";
import CheckIcon from "../../Assets/Images/bi_check_icon.png";
import {
  getAddedAssetTakeDownRequestAction,
  startTakedownProcessAction,
} from "../../Store/DMCA/actions";
import CustomSpinner from "../UI/CustomSpinner.jsx";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    "& .MuiPaper-root": {
      padding: "30px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        padding: "20px 10px",
        margin: "10px",
      },
    },
  },
  electronicSignatureContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& h4": {
      margin: 0,
      ...theme.typography.body16,
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        marginBottom: "9px",
      },
    },
  },
  conditionModalLine: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "20px",
    "& .MuiButtonBase-root": {
      padding: "0px",
      marginRight: "10px",
    },
    "& p": {
      margin: 0,

      maxWidth: "490px",
      ...theme.typography.body16,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
  },
  signatureContainer: {
    marginLeft: "15px",
    height: "60px",
    flex: 1,
    background: theme.palette.lightGreen2,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0",
    },
    "& p": {
      padding: "12px 20px",
      margin: 0,
      ...theme.typography.cursive,
    },
  },
  buttonsContainer: {
    marginTop: "20px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  submitButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    marginLeft: "7px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      margin: "0 !important",
    },
    "&:disabled": {
      opacity: "0.5 !important",
    },
  },
  cancelButton: {
    marginRight: "7px !important",
    fontSize: "18px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      margin: 0,
      marginTop: "13px !important",
    },
  },
  submitContainer: {
    padding: "5px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& h2": {
      margin: 0,
      marginBottom: "13px",
      ...theme.typography.heading24,
      fontWeight: 700,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },

    "& p": {
      margin: 0,
      marginBottom: "25px",
      ...theme.typography.body18,
      lineHeight: "34px",
      maxWidth: "420px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    "& button": {
      maxWidth: "250px !important",
      fontSize: "18px !important",
      fontWeight: "400 !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "40px 5px",
    },
  },
  checkIcon: {
    height: "50px",
    width: "50px",
    marginBottom: "25px",
  },
}));

const ConditionModal = ({ open, onClose, selectedImage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);
  const {
    loadingResp: loading,
    error,
    errorMessage,
  } = useSelector((state) => state.DMCA);

  const [isSubmited, setIsSubmited] = useState(false);
  const [condition1CheckBox, setCondition1CheckBox] = useState(false);
  const [condition2CheckBox, setCondition2CheckBox] = useState(false);

  const onSubmit = () => {
    dispatch(
      startTakedownProcessAction({
        takedown_id: selectedImage._id,
        asset_id: selectedImage.asset_id,
        page_url: selectedImage.match_details.page_url,
        match_id: selectedImage.match_id,
      })
    );
    setIsSubmited(true);
  };

  const handleClose = () => {
    setIsSubmited(false);
    onClose();
  };

  const successClose = () => {
    handleClose();
    dispatch(getAddedAssetTakeDownRequestAction());
  };

  return (
    <CustomPopup
      open={open}
      onClose={handleClose}
      className={classes.modalContainer}
      noPadding
    >
      {loading ? (
        <CustomSpinner />
      ) : (
        <>
          {isSubmited ? (
            <div className={classes.submitContainer}>
              {error ? (
                <p> {errorMessage}</p>
              ) : (
                <>
                  <img
                    src={CheckIcon}
                    alt="checkIcon"
                    className={classes.checkIcon}
                  />
                  <h2>Your request has been submitted.</h2>
                  <p>
                    Please give 7-14 days to process. If any additional
                    information is needed you will receive a message in the DMCA
                    Inbox.
                  </p>
                </>
              )}

              <CustomButton yellow onClick={successClose}>
                {error ? "Close" : "Thankyou"}
              </CustomButton>
            </div>
          ) : (
            <>
              {" "}
              <div className={classes.conditionModalLine}>
                <CheckBoxCustom
                  checked={condition1CheckBox}
                  onChange={() => setCondition1CheckBox((current) => !current)}
                />
                <p>
                  I hereby state that I have a good faith belief that the
                  disputed use of the copyrighted material is not authorized by
                  the copyright owner, its agent, or the law (e.g., as a fair
                  use).
                </p>
              </div>
              <div className={classes.conditionModalLine}>
                <CheckBoxCustom
                  checked={condition2CheckBox}
                  onChange={() => setCondition2CheckBox((current) => !current)}
                />
                <p>
                  I hereby state that the information in this notice is accurate
                  and, under penalty of perjury, that I am the owner, or
                  authorized to act on behalf of, the owner, of the copyright or
                  of an exclusive right under the copyright that is allegedly
                  infringed.
                </p>
              </div>
              <div className={classes.electronicSignatureContainer}>
                <h4>Electronic Signature</h4>
                <div className={classes.signatureContainer}>
                  <p>{userData?.full_name ? userData?.full_name : ""} </p>
                </div>
              </div>
              <div className={classes.buttonsContainer}>
                <CustomButton
                  className={classes.cancelButton}
                  onClick={handleClose}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  yellow
                  className={classes.submitButton}
                  onClick={onSubmit}
                  disabled={!(condition2CheckBox && condition1CheckBox)}
                >
                  Submit
                </CustomButton>
              </div>
            </>
          )}
        </>
      )}
    </CustomPopup>
  );
};

const CheckBoxCustom = ({ checked, onChange }) => (
  <Checkbox
    checked={checked}
    onChange={onChange}
    sx={{
      "& .MuiSvgIcon-root": { fontSize: 28 },
      color: "#408266",
      "&.Mui-checked": {
        color: "#408266",
      },
    }}
  />
);

export default ConditionModal;
