import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import Icon from "../../Assets/Images/check_circle_icon.png";
import CustomButton from "../UI/CustomButton.jsx";

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    background: theme.palette.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& h1": {
      textAlign: "center",
      margin: 0,
      marginBottom: "15px",
      marginTop: "40px",
      fontSize: "55px",
      fontWeight: "700",
    },
    "& p": {
      margin: 0,
      textAlign: "center",
      marginBottom: "30px",
      ...theme.typography.body24,
    },
    [theme.breakpoints.down("sm")]: {
      "& h1": {
        fontSize: "34px",
      },
      "& p": {
        marginBottom: "25px",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  button: {
    maxWidth: "260px !important",
    background: `${theme.palette.lightGreen} !important`,
    color: `${theme.palette.white} !important`,
    height: "60px !important",
    fontWeight: "500 !important",
    fontSize: "24px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      height: "40px !important",
    },
  },
  img: {
    height: "50px",
    width: "50px",
  },
}));

const CongrulationComponent = ({ plan }) => {
  const classes = useStyle();
  const { onboardingToken } = useSelector((state) => state.authReducer);

  const handleClick = () => {
    localStorage.setItem("token", onboardingToken);
    window.location.href = "/search";
  };

  return (
    <div className={classes.container}>
      <img src={Icon} alt="check circle" className={classes.icon} />
      <h1>Congratulations!</h1>
      <p>You are now subscribed to our {plan}</p>
      <CustomButton className={classes.button} onClick={handleClick}>
        See My Results
      </CustomButton>
    </div>
  );
};

export default CongrulationComponent;
