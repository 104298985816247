import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import API from "../../Axios/Axios";

function* getUserSaga() {
  try {
    yield put({ type: actionTypes.SET_USER_LOADING });
    const { data } = yield API.get("/user");
    if (data.success) {
      yield put({
        type: actionTypes.GET_USER_INFO_SUCCESS,
        payload: data.data.user,
      });
    } else {
      yield put({
        type: actionTypes.GET_USER_INFO_FAILURE,
        payload: data?.message || "Unable to get user information",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_USER_INFO_FAILURE,
      payload:
        error?.response?.data?.message || "Unable to get user information",
    });
  }
}

function* updateUserInfoSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_USER_LOADING });
    const { data } = yield API.post("/user/edit", payload);
    if (data.success) {
      yield put({
        type: actionTypes.UPDATE_USER_INFO_SUCCESS,
      });
      yield put({
        type: actionTypes.GET_USER_INFO,
      });
      toast.success("User updated successfully");
    } else {
      yield put({
        type: actionTypes.UPDATE_USER_INFO_FAILURE,
        payload: data?.message || "Unable to update user",
      });

      toast.error("Unable to update user");
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_USER_INFO_FAILURE,
      payload: error?.response?.data?.message || "Unable to update user",
    });
    toast.error("Unable to update user");
  }
}

function* userSaga() {
  yield all([
    yield takeLatest(actionTypes.GET_USER_INFO, getUserSaga),
    yield takeLatest(actionTypes.UPDATE_USER_INFO, updateUserInfoSaga),
  ]);
}

export default userSaga;
