import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import API from "../../Axios/Axios";
import * as actionTypes from "./actionTypes";

function* getOwnUrls() {
  try {
    yield put({ type: actionTypes.SET_OWN_URL_LOADING });
    const { data } = yield API.get("/own-url/fetch");

    if (data.success) {
      yield put({
        type: actionTypes.GET_OWN_URLS_SUCCESS,
        payload: data.data,
        noUrls: !(data.data.length > 0),
      });
    } else {
      yield put({
        type: actionTypes.GET_OWN_URLS_FAILURE,
        payload: "Unable to load urls.",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_OWN_URLS_FAILURE,
      payload: "Unable to load urls.",
    });
  }
}

function* addOwnUrl({ payload }) {
  try {
    yield put({ type: actionTypes.SET_OWN_URL_LOADING });
    const { data } = yield API.post("/own-url/save", payload);
    if (data.success) {
      yield put({
        type: actionTypes.ADD_OWN_URL_SUCCESS,
      });
      yield put({ type: actionTypes.GET_OWN_URLS });
    } else {
      yield put({
        type: actionTypes.ADD_OWN_URL_FAILURE,
        payload: "Unable to add url.",
      });
      toast.error("Unable to add url.");
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_OWN_URL_FAILURE,
      payload: "Unable to add url.",
    });
    toast.error("Unable to add url.");
  }
}

function* subscriptionSaga() {
  yield all([
    yield takeLatest(actionTypes.GET_OWN_URLS, getOwnUrls),
    yield takeLatest(actionTypes.ADD_OWN_URL, addOwnUrl),
  ]);
}

export default subscriptionSaga;
