import React, { useState } from "react";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import AuthWrapper from "../../Components/AuthWrapper/AuthWrapper.jsx";
import LoginIcon from "../../Assets/Images/login_icon.png";
import CustomTextField from "../../Components/UI/CustomTextField.jsx";
import { isEmailValid } from "../../Helpers/functions";
import Constants from "../../Assets/Constants/index";
import { loginAction } from "../../Store/Auth/actions";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
    height: "calc(100% - 40px)",
  },
  loginIcon: {
    height: "60px",
    width: "60px",
    marginBottom: "20px",
  },
  headText: {
    ...theme.typography.heading32,
    textAlign: "center",
    marginBottom: "20px",
  },

  button: {
    ...theme.typography.button,
    color: theme.palette.white,
    background: theme.palette.lightGreen,
    width: "100%",
    margin: "5px",
    marginTop: "10px",
    height: "55px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
  },
  alert: {
    marginBottom: "10px",
  },
}));
const LoginBack = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    error: apiError,
    errorMessage,
  } = useSelector((state) => state.authReducer);

  const [credentials, setCredentials] = useState({
    password: "",
    email_address: "",
  });
  const [error, setError] = useState({
    email_address: "",
    password: "",
  });

  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const validateFields = () => {
    let valid = true;
    const errorObj = { email_address: "", password: "" };

    if (!isEmailValid(credentials.email_address)) {
      valid = false;
      if (!credentials.email_address) {
        errorObj.email_address = Constants.EMAIL_REQUIRED;
      } else {
        errorObj.email_address = Constants.INVALID_EMAIL;
      }
    }

    if (!credentials.password) {
      errorObj.password = Constants.PASSWORD_REQUIRED;
    }
    setError(errorObj);
    return valid;
  };

  const handleLogin = () => {
    if (validateFields()) {
      dispatch(
        loginAction(
          {
            email_address: credentials.email_address,
            password: credentials.password,
          },
          navigate
        )
      );
    }
  };

  return (
    <AuthWrapper hideBottomLine boxWithoutImage>
      <StyledEngineProvider injectFirst>
        <div className={classes.bodyContainer}>
          <img src={LoginIcon} alt="login icon" className={classes.loginIcon} />
          <Typography className={classes.headText}>Log Back In</Typography>

          {apiError && (
            <Alert className={classes.alert} severity="error">
              {errorMessage}
            </Alert>
          )}

          <CustomTextField
            label="Enter Your Email"
            type="email"
            name="email_address"
            variant="outlined"
            value={credentials.email_address}
            onChange={handleChange}
            disabled={loading}
            error={Boolean(error.email_address)}
            helperText={error.email_address}
          />

          <CustomTextField
            label="Password"
            type={passwordVisibility ? "text" : "password"}
            name="password"
            variant="outlined"
            value={credentials.password}
            onChange={handleChange}
            disabled={loading}
            error={Boolean(error.password)}
            helperText={error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                    edge="end"
                  >
                    {passwordVisibility ? (
                      <MdOutlineVisibility />
                    ) : (
                      <MdOutlineVisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {loading ? (
            <CustomSpinner />
          ) : (
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleLogin}
            >
              Login to Loti
            </Button>
          )}
        </div>
      </StyledEngineProvider>
    </AuthWrapper>
  );
};

export default LoginBack;
