import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import {
  Box,
  Button,
  Drawer,
  Grid,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "../../Assets/Images/menu.png";
import Script from "../Script/Script.jsx";

const useStyles = makeStyles((theme) => ({
  subLogo:{
    fontSize:'11px',
    marginLeft:'15px',
    fontWeight:"600"
  },
  greenBar: {
    background: theme.palette.darkGreen,
    height: "18px",
    [theme.breakpoints.down("sm")]: {
      height: "5px",
    },
    width: "100vw",
  },
  tryTodayContainer: {
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.black,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.white,
      borderTop: `1px solid ${theme.palette.grey4}`,
      margin: "10px 8px",
      padding: "7px 16px",
      backgroundColor: theme.palette.lightGreen,
    },
  },
  menuContainer: {
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.black,
    [theme.breakpoints.down("sm")]: {
      borderTop: `1px solid ${theme.palette.grey4}`,
      margin: 0,
      padding: "17px 24px",
    },
    "&.active": {
      "& .MuiTypography-root": {
        fontWeight: "800 !important",
      },
    },
  },
  optionName: {
    fontWeight: "500 !important",
  },
  wrapper: {
    padding: "20px 80px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 40px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      height: "25px",
      width: "50px",
    },
  },
  tryButton: {
    ...theme.typography.button,
    backgroundColor: `${theme.palette.lightGreen} !important`,
    color: theme.palette.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.darkGreen,
    },
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  link: {
    ...theme.typography.link,
    textDecoration: "none",
    color: theme.palette.black,
    padding: "0 5px",
  },
}));

const AuthHeader = () => {
  const classes = useStyles();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { pathname } = useLocation();

  const [showDrawer, setShowDrawer] = useState(false);

  const activeLink = `/${
    pathname.split("/").length > 2
      ? `${pathname.split("/")[1]}/${pathname.split("/")[2]}`
      : pathname.split("/")[1]
  }`;

  const toggleDrawer = () => {
    setShowDrawer((current) => !current);
  };

  return (
    <div>
      <Script />
      <div className={classes.greenBar} />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.wrapper}
      >
        <Grid item sm={3} md={4}>
          <img src="logo.svg" alt="logo" className={classes.logo} height="30" width="150"/>
          <div className={classes.subLogo}>Powered by <a href="http://app.goloti.com/">loti</a></div>
        </Grid>
        <Grid item sm={9} md={6}>
          {isMobileScreen ? (
            <img src={MenuIcon} alt="menu icon" onClick={toggleDrawer} />
          ) : (
            <StyledEngineProvider injectFirst>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  
                </Grid>
                <Grid item>
                  
                </Grid>
                <Grid item>
                  <Link to="/" className={classes.link}>
                    Login
                  </Link>
                </Grid>
                <Grid item>
                <a href="/onboarding" className={classes.tryTodayContainer}>
                  <Button
                    variant="contained"
                    className={classes.tryButton}
                  >
                    Try Today
                  </Button>
                  </a>
                </Grid>
              </Grid>
            </StyledEngineProvider>
          )}
        </Grid>
      </Grid>
      {isMobileScreen && (
        <Drawer anchor="right" open={showDrawer} onClose={toggleDrawer}>
          <Box onKeyDown={toggleDrawer}>
            {/* <a
              href="https://goloti.com/how-loti-works/"
              className={classes.menuContainer}
            >
              <Typography variant="body16" className={classes.optionName}>
                How it works
              </Typography>
            </a>
            <a
              href="https://goloti.com/pricing/"
              className={classes.menuContainer}
            >
              <Typography variant="body16" className={classes.optionName}>
                Pricing
              </Typography>
            </a> */}
            <Link
              to="/login"
              className={clsx(
                classes.menuContainer,
                activeLink === "/login" ? "active" : ""
              )}
            >
              <Typography variant="body16" className={classes.optionName}>
                Login
              </Typography>
            </Link>
            <a href="/onboarding" className={classes.tryTodayContainer}>
              <Typography variant="body16" className={classes.optionName}>
                Try Today
              </Typography>
            </a>
          </Box>
        </Drawer>
      )}
    </div>
  );
};

export default AuthHeader;
