import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  errorMessage: "",
  plans: [],
  activeSubscription: null,
  bills: [],
  activeSubscriptionDetails: {},
};

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUBSCRIPTIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        loading: false,
        plans: action.payload,
      };
    case actionTypes.GET_SUBSCRIPTION_PLANS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.GET_ACTIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        activeSubscription: action.payload.plan,
        activeSubscriptionDetails: {
          nextPayment: action.payload.next_payment_date,
          paymentInUsd: action.payload.payment_in_usd,
          availbleSearches: action.payload.availbleSearches,
          userSearches: action.payload.user_searches,
        },
      };
    case actionTypes.GET_ACTIVE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        activeSubscription: null,
        errorMessage: action.payload,
        activeSubscriptionDetails: {},
      };

    case actionTypes.GET_BILLS_SUCCESS:
      return {
        ...state,
        loading: false,
        bills: action.payload,
      };
    case actionTypes.GET_BILLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        bills: [],
      };

    case actionTypes.UPGRADE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.UPGRADE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default subscription;
