import { Alert, Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";
import { isEmailValid } from "../../Helpers/functions";
import { onboardUserAction } from "../../Store/Auth/actions";
import {
  getDashBoardSearch,
  searchAssetAction,
  startProgressLoader,
  setProgressValue,
} from "../../Store/Matches/actions";
import { saveAssetAction } from "../../Store/Uploads/actions";
import CustomButton from "../UI/CustomButton.jsx";
import CustomFormInputType2 from "../UI/CustomFormInputType2.jsx";
import CustomSpinner from "../UI/CustomSpinner.jsx";
// import CustomSpinner2 from "../UI/CustomSpinner2.jsx";
import CirculerProgressBar from "../UI/CirculerProgressBar.jsx";

const useStyles = makeStyles((theme) => ({
  headTitle: {
    ...theme.typography.heading36,

    margin: 0,
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "5px",
      fontSize: "24px",
    },
  },
  para: {
    ...theme.typography.body24,
    margin: 0,

    marginBottom: "25px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginBottom: "20px",
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  nextButton: {
    fontSize: "20px !important",
    height: "70px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px !important",
      fontSize: "16px !important",
      marginRight: "0px !important",
      maxWidth: "300px !important",

      height: "40px !important",
    },
  },

  image: {
    maxWidth: "90%",
    maxHeight: "500px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& h2": {
      marginTop: "20px",
      marginBottom: "30px",
      textAlign: "center",
    },
  },
}));

const randomNumbers = () => {
  const num1 = Math.floor(Math.random() * 25) + 1;
  const num2 = Math.floor(Math.random() * 25) + 1;
  const num3 = Math.floor(Math.random() * 25) + 1;
  const num4 = 100 - num1 - num2 - num3;
  return [num2, num4, num1, num3];
};

const StepEmail = ({
  data,
  faces,
  faceId,
  setProgress,
  handleInputChange,
  uploadedImage,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const {
    loading,
    error: apiError,
    errorMessage,
  } = useSelector((state) => state.authReducer);
  const { loading: matchLoading, progressLoader } = useSelector(
    (state) => state.matches
  );
  const { loading: uploadsLoading } = useSelector((state) => state.Uploads);
  const [error, setError] = useState("");
  const [live, setLive] = useState(false);
  const randomeValues = randomNumbers();
  function updateProgress(progressData) {
    setLive(progressData);
  }
  useEffect(() => {
    if (progressLoader === false && live) {
      setProgress(live);
      setLive(false);
    }
  }, [progressLoader]);

  const handleNext = () => {
    if (data.email && isEmailValid(data.email)) {
      setLive(false);
      dispatch(startProgressLoader());
      dispatch(setProgressValue(0));
      dispatch(
        onboardUserAction(data.email, (token) => {
          if (faces[faceId]) {
            dispatch(setProgressValue(randomeValues[0]));
            dispatch(
              saveAssetAction(
                faces[faceId].asset_id,
                true,
                token,
                () => {
                  dispatch(setProgressValue(randomeValues[1]));
                  dispatch(
                    searchAssetAction(
                      faces[faceId].asset_id,
                      () => {
                        dispatch(setProgressValue(randomeValues[2]));
                        dispatch(
                          getDashBoardSearch(true, token, (successData) => {
                            dispatch(setProgressValue(randomeValues[3]));
                            if (successData.exact_matches > 0) {
                              updateProgress((current) => ({
                                ...current,
                                key: 6,
                                matchFound: true,
                                needToShowGreatNews: false,
                              }));
                            } else if (
                              successData.exact_matches < 1 &&
                              successData.finalResultSorted.length < 1
                            ) {
                              updateProgress({
                                key: 7,
                                matchFound: false,
                                noExactMatch: true,
                                needToShowGreatNews: true,
                              });
                            } else {
                              updateProgress({
                                key: 6,
                                matchFound: true,
                                noExactMatch: true,
                                needToShowGreatNews: false,
                              });
                            }
                          })
                        );
                      },
                      () => {
                        setProgress({ key: 7, matchFound: false });
                      },
                      true,
                      token
                    )
                  );
                }
                // () => {
                //
                // }
              )
            );
          } else {
            setProgress((current) => ({
              ...current,
              key: 7,
              matchFound: false,
            }));
          }
        })
      );

      return;
    }
    setError("Invalid Email");
  };

  return (
    <Grid container justifyContent="center">
      {(matchLoading || uploadsLoading || progressLoader) && !apiError ? (
        <div className={classes.loadingContainer}>
          <h2>
            Please wait while we search
            <br />
            more than 50 million sites for matches…
          </h2>
          {/* <CustomSpinner2 /> */}
          <StyledEngineProvider injectFirst>
            <CirculerProgressBar seconds={0} />
          </StyledEngineProvider>
        </div>
      ) : (
        <>
          <Grid item sm={6}>
            <h2
              className={clsx(
                classes.headTitle,
                faces[faceId] ? "" : classes.textCenter
              )}
            >
              Let’s get your email address so we know where to send your alerts.
            </h2>
            <p
              className={clsx(
                classes.para,
                faces[faceId] ? "" : classes.textCenter
              )}
            >
              We’ll send you an initial verification email.
            </p>
            {apiError && <Alert severity="error">{errorMessage}</Alert>}
            <CustomFormInputType2
              type="text"
              placeholder="Email"
              error={Boolean(error)}
              helperText={error}
              name="email"
              value={data.email}
              onChange={handleInputChange}
            />

            {!isMobileScreen &&
              (loading ? (
                <CustomSpinner center />
              ) : (
                <CustomButton
                  yellow
                  className={classes.nextButton}
                  onClick={handleNext}
                  disabled={loading}
                >
                  Next
                </CustomButton>
              ))}
          </Grid>

          {faces[faceId] && (
            <Grid
              item
              sm={6}
              container
              alignItems="center"
              justifyContent={{ sm: "flex-end", xs: "center" }}
            >
              <img
                src={URL.createObjectURL(uploadedImage)}
                className={classes.image}
              />
            </Grid>
          )}
          {isMobileScreen &&
            (loading ? (
              <CustomSpinner center />
            ) : (
              <CustomButton
                yellow
                disabled={loading}
                className={classes.nextButton}
                onClick={handleNext}
              >
                Next
              </CustomButton>
            ))}
        </>
      )}
    </Grid>
  );
};

export default StepEmail;
