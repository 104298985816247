import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import BillingHead from "../../Components/Billing/BillingHead.jsx";
import BillingHistory from "../../Components/Billing/BillingHistory.jsx";
import {
  getBillsAction,
  getSubscriptionPlansAction,
} from "../../Store/Subscriptions/actions";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";

const useStyles = makeStyles(() => ({
  billingContainer: {
    height: "100%",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

const Billing = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeSubscription, activeSubscriptionDetails, bills, loading } =
    useSelector((state) => state.Subscriptions);

  useEffect(() => {
    // addSBL();
  }, []);

  useEffect(() => {
    dispatch(getBillsAction());
    dispatch(getSubscriptionPlansAction());
  }, [dispatch]);

  return (
    <div className={classes.billingContainer}>
      {loading ? (
        <div className={classes.loaderContainer}>
          <CustomSpinner center />
        </div>
      ) : (
        <>
          <BillingHead
            activeSubscription={activeSubscription}
            activeSubscriptionDetails={activeSubscriptionDetails}
          />
          <BillingHistory
            activeSubscription={activeSubscription}
            bills={bills}
          />
        </>
      )}
    </div>
  );
};

export default Billing;
