import React, { useState } from "react";
import { Button, StyledEngineProvider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowRoundBack } from "react-icons/io";
import AuthWrapper from "../../Components/AuthWrapper/AuthWrapper.jsx";
import LockIcon from "../../Assets/Images/lock_icon.png";
import CustomTextField from "../../Components/UI/CustomTextField.jsx";
import { forgotPasswordAction } from "../../Store/Auth/actions";
import { isEmailValid } from "../../Helpers/functions";
import Constants from "../../Assets/Constants/index";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";

const useStyles = makeStyles((theme) => ({
  backIcon: {
    fontSize: "25px",
    marginRight: "5px",
  },
  backContainer: {
    display: "flex",
    alignItems: "center",
    height: "20px",
    cursor: "pointer",
  },
  bodyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
    height: "calc(100% - 40px)",
  },
  lockIcon: {
    height: "60px",
    width: "60px",
    marginBottom: "20px",
  },
  headText: {
    ...theme.typography.heading32,
    textAlign: "center",
  },
  bodyText: {
    ...theme.typography.body18,
    marginTop: "20px",
    textAlign: "center",
    marginBottom: "20px",
  },

  button: {
    ...theme.typography.button,
    color: theme.palette.white,
    background: theme.palette.lightGreen,
    width: "100%",
    margin: "5px",
    marginTop: "10px",
    height: "55px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, error, errorMessage } = useSelector(
    (state) => state.authReducer
  );

  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const validateEmail = () => {
    let validation = true;

    if (!isEmailValid(email)) {
      validation = false;
      if (!email) {
        setValidationError(Constants.EMAIL_REQUIRED);
      } else {
        setValidationError(Constants.INVALID_EMAIL);
      }
    }

    return validation;
  };

  const handleSubmit = () => {
    if (validateEmail()) {
      setValidationError("");
      dispatch(forgotPasswordAction(email, navigate));
    }
  };

  return (
    <AuthWrapper hideBottomLine boxWithoutImage>
      <StyledEngineProvider injectFirst>
        <div className={classes.backContainer} onClick={() => handleBack()}>
          <IoIosArrowRoundBack className={classes.backIcon} />
          <Typography variant="body16_500">Back</Typography>
        </div>
        <div className={classes.bodyContainer}>
          <img src={LockIcon} alt="lock icon" className={classes.lockIcon} />
          <Typography className={classes.headText}>Reset Password</Typography>
          <Typography className={classes.bodyText}>
            Enter the email associated with your account and we’ll send an email
            with instructions to reset your password.
          </Typography>
          <CustomTextField
            label="Enter Email"
            type="email"
            name="email"
            error={Boolean(validationError || error)}
            errorText={validationError || errorMessage}
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            disabled={loading}
          />
          {loading ? (
            <CustomSpinner />
          ) : (
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleSubmit}
            >
              Send Instructions
            </Button>
          )}
        </div>
      </StyledEngineProvider>
    </AuthWrapper>
  );
};

export default ForgotPassword;
