import { useNavigate } from "react-router-dom";
import { Box, Grid, LinearProgress, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Step4 from "../../Components/Onboarding/Step4.jsx";
import StepHiddenResult from "../../Components/Onboarding/StepHiddenResult.jsx";
import StepActionPermission from "../../Components/Onboarding/StepActionPermission.jsx";
import StepForm from "../../Components/Onboarding/StepForm.jsx";
import StepType1 from "../../Components/Onboarding/StepType1.jsx";
import StepFromAds from "../../Components/Onboarding/StepFromAds.jsx";
import StepUpload from "../../Components/Onboarding/StepUpload.jsx";
import CongrulationComponent from "../../Components/Onboarding/CongrulationComponent.jsx";
import CustomButton from "../../Components/UI/CustomButton.jsx";
import StepEmail from "../../Components/Onboarding/StepEmail.jsx";
import Script from "../../Components/Script/Script.jsx";
import AccountAlert from "../../Components/Onboarding/AccountAlert.jsx";
import {plans} from "../../constants";

const useStyles = makeStyles((theme) => ({
  subLogo: {
    fontSize: "9px",
    fontWeight: "600",
    marginTop:"-8px"
  },
  pageContainer: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 10px",
  },

  onBoardingContainer: {
    width: "70vw",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  row1: {
    marginTop: "30px",
    marginBottom: "24px",
    "& img": {
      height: "36px",
      width: "72px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "11px",
      marginBottom: "20px",
      "& img": {
        height: "26px",
        width: "53px",
      },
    },
  },
  extraMarginTopForRow: {
    marginTop: "109px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "60px",
    },
  },
  counter: {
    margin: 0,
    ...theme.typography.body20,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  progress: {
    "&.MuiLinearProgress-root": {
      height: "8px ",
      backgroundColor: theme.palette.grey4,
      [theme.breakpoints.down("sm")]: {
        borderRadius: "5px",
      },
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.lightGreen,
    },
  },
  backContainer: {
    width: "100%",
  },
  back: {
    marginLeft: "80px",
    cursor: "pointer",
    marginTop: "45px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: "23px",
    },
    "& p": {
      ...theme.typography.body20,
      margin: 0,
      marginLeft: "5px",
    },

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginTop: "15px",
      fontSize: "16px",
      "& p": {
        fontSize: "14px",
      },
      "& svg": {
        fontSize: "16px",
      },
    },
  },

  box: {
    marginBottom: "35px",
    width: "100%",
  },

  exitStepperContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      marginTop: "100px",
    },
  },
  exitStepperButton: {
    height: "65px !important",
    width: "200px !important",
    fontSize: "20px !important",
    [theme.breakpoints.down("sm")]: {
      height: "auto !important",
      marginTop: "35px !important",
      fontSize: "16px !important",
    },
  },
  exitStepperHead: {
    marginTop: "100px",
    marginBottom: "70px",
    textAlign: "center",
    margin: 0,
    ...theme.typography.heading36,
    "& a": {
      color: theme.palette.lightGreen,
      textDecoration: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "40px",
      marginBottom: "5px",
      fontSize: "24px",
      lineHeight: "34px",
      "& a": {
        textDecoration: "underline",
      },
    },
  },
  c2: {
    marginBottom: "10px",
  },
  width: {
    width: "65%",
    [theme.breakpoints.down("lg")]: {
      width: "78%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

// const plans = [
//   // {
//   //   name: "Standard Plan",
//   //   description:
//   //     "Twice a month image and video alerts as well as unlimited access to view your image results.",
//   //   points: [
//   //     "One image match per month",
//   //     "View all image results at any time",
//   //     "Two alerts per month",
//   //     "Access to DMCA takedown process",
//   //   ],
//   //   price: "13",
//   //   gatewayIdentifier: "loti-standard",
//   // },
//   {
//     name: "Takedown Plan",
//     description: (
//       <>
//         We found both image and video results that closely match your upload.
//         <br />
//         <br />
//         With our Advanced Plan, you can view all image and video results and get
//         access to our DMCA process to take them down.
//       </>
//     ),
//     points: [
//       "One image match per month",
//       "Unlimited access to all image and video results",
//       "Four alerts per month",
//       "Access to DMCA takedown process",
//     ],
//     price: "25",
//     gatewayIdentifier: "price_1MNVjiAFrcXOlCub0vwDbaQO",
//   },
//   {
//     name: "Basic Alerts",
//     description:
//       "With our Basic Alerts plan once a month we will send you a notification if we find any images or videos that match your image.  ",
//     points: ["One image match per month", "One alert per month"],
//     sub: (
//       <>
//         (One image will accurately detect
//         <br /> 98% of images and videos)
//       </>
//     ),
//     price: "8",
//     gatewayIdentifier: "price_1MNVjtAFrcXOlCub3mrf40ut",
//   },
//   {
//     name: "Creator Plan",
//     points: [
//       "General Face Search (98% Accurate)",
//       "20 Exact Match Images/Videos",
//       "Customizable (Daily to Weekly) Alerts",
//       "Unlimited DMCA takedowns",
//     ],
//     price: "100",
//     gatewayIdentifier: "price_1MNVjcAFrcXOlCubLBqX8Vpl",
//   },
// ];

const Onboarding = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { referrer } = document;
  const refURLs = [
    "https://goloti.com/",
    "https://app.goloti.com/login",
    "https://staging.goloti.com/login",
  ];

  const { count, faces, searches } = useSelector((state) => state.matches);

  const [progress, setProgress] = useState({
    key: 1,
    matchFound: false,
    noExactMatch: false,
  });
  const ref =
    typeof referrer !== "undefined" &&
    referrer.length > 0 &&
    refURLs.includes(referrer)
      ? 0
      : 1;
  const [stepFromAds, setStepFromAds] = useState(ref);

  const [exitStepper, setExitStepper] = useState({
    value: false,
    key: null,
  });
  const [data, setData] = useState({
    eighteenPlus: false,
    email: "",
    full_name: "",
    password: "",
    country: "",
    street: "",
    suite: "",
    city: "",
    state: "",
    zip_code: "",
    expiryDate: undefined,
    contentCreator: false,
  });
  const [faceId, setFaceId] = useState(0);
  const [uploadedImage, setUploadedImage] = useState();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleInputChange = (e) =>
    setData((current) => ({ ...current, [e.target.name]: e.target.value }));

  const ExitContents = [
    {
      title: (
        <h2 className={classes.exitStepperHead}>
          Please see{" "}
          <a href="https://www.missingkids.org/gethelpnow/isyourexplicitcontentoutthere">
            NCMEC
          </a>{" "}
          which provides free services for people under the age of 18.
        </h2>
      ),
      button: (
        <CustomButton
          yellowFilled
          className={classes.exitStepperButton}
          onClick={() => {
            window.location.href =
              "https://www.missingkids.org/gethelpnow/isyourexplicitcontentoutthere";
          }}
        >
          Go Now!
        </CustomButton>
      ),
    },
    {
      title: (
        <h2 className={clsx(classes.exitStepperHead, classes.width)}>
          You can log back in whenever you are ready to take the next steps.
          {!isMobileScreen && <br />}
          We will be here.
        </h2>
      ),
      button: (
        <CustomButton
          yellowFilled
          className={classes.exitStepperButton}
          onClick={() => {
            window.location.href = "https://goloti.com/";
          }}
        >
          Back to Homepage
        </CustomButton>
      ),
    },
    {
      title: (
        <h2 className={classes.exitStepperHead}>
          Image does not contain any more faces.
        </h2>
      ),
      button: (
        <CustomButton
          yellowFilled
          className={classes.exitStepperButton}
          onClick={() => {
            window.location.href = "https://goloti.com/";
          }}
        >
          Back to Homepage
        </CustomButton>
      ),
    },
  ];

  const showBack = () => {
    if (!exitStepper.value) {
      return true;
    }

    return false;
  };

  const handleNext = () => {
    setProgress((current) => ({ ...current, key: current.key + 1 }));
  };

  const handleBack = () => {
    if (progress.key === 1) {
      navigate("/");
      return;
    }
    setProgress({ key: 1, matchFound: false, noExactMatch: false });
    // if (progress.key <= 4) {
    //   setFaceId(0);
    // }

    // if (progress.key === 5 && faces.length === 0) {
    //   setProgress((current) => ({ ...current, key: 3 }));
    //   return;
    // }

    // if (progress.key === 7 && searches.length <= 0) {
    //   setProgress((current) => ({ ...current, key: 3 }));
    //   return;
    // }
    // setProgress((current) => ({ ...current, key: current.key - 1 }));
  };

  // Only for Account Alert
  const { loading: matchLoading, progressLoader } = useSelector(
    (state) => state.matches
  );
  const { loading: uploadsLoading } = useSelector((state) => state.Uploads);

  const handleChange = (e) => {
    setData((current) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));
  };

  const getPlanInfo = () => {
    let selectedPlan;
    if (count > 0 && data.contentCreator) {
      // eslint-disable-next-line
      selectedPlan = plans[2];
    } else if (progress.matchFound) {
      // eslint-disable-next-line
      selectedPlan = plans[0];
    } else {
      // eslint-disable-next-line
      selectedPlan = plans[1];
    }

    return selectedPlan;
  };

  const showNextFace = () => {
    if (faces.length - 1 > faceId) {
      setFaceId((current) => current + 1);
      return;
    }
    setExitStepper({ value: true, key: 2 });
  };

  return (
    <div className={classes.pageContainer}>
      <Script />
      {showBack() && (
        <div className={classes.backContainer}>
          <div className={classes.back} onClick={() => handleBack()}>
            <MdOutlineKeyboardBackspace />
            <p>{progress.key === 1 ? "Home" : "Start Over"} </p>
          </div>
        </div>
      )}
      <div className={classes.onBoardingContainer}>
        {exitStepper.value ? (
          <div className={classes.exitStepperContainer}>
            <img src="logo.svg" />
            <div className={classes.subLogo}>Powered by <a href="http://app.goloti.com/">loti</a></div>

            {ExitContents[exitStepper.key].title}

            {ExitContents[exitStepper.key].button}
          </div>
        ) : (
          <div className={classes.c2}>
            <Grid
              container
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              className={clsx(
                classes.row1,
                !showBack() ? classes.extraMarginTopForRow : ""
              )}
            >
              <div>
              <img src="logo.svg" />
              <div className={classes.subLogo}>Powered by <a href="http://app.goloti.com/">loti</a></div>
              </div>

              <div>
                <h4 className={classes.counter}>{progress.key} of 9</h4>
              </div>
            </Grid>
            <Box className={classes.box}>
              <LinearProgress
                variant="determinate"
                value={progress.key * 11.11}
                className={classes.progress}
              />
            </Box>
            {progress.key === 1 && (
              <>
                {stepFromAds === 1 && (
                  <StepFromAds
                    heading="Do you know if you have any intimate images that have been posted online without your consent?"
                    subText="Loti can help you find out in less than 30 seconds. Click next and upload a profile image. In 30 seconds or less, we will tell you if we found any matching images or videos."
                    onClickTryNow={() => {
                      setStepFromAds(0);
                    }}
                  />
                )}

                {stepFromAds === 0 && (
                  <StepType1
                    heading="Are you over the age of 18?"
                    subText="Loti is only for individuals over the age of 18."
                    onClickYes={() => {
                      setData((current) => ({
                        ...current,
                        eighteenPlus: true,
                      }));
                      handleNext();
                    }}
                    onClickNo={() => setExitStepper({ value: true, key: 0 })}
                  />
                )}
              </>
            )}
            {progress.key === 2 && (
              <StepType1
                inverseButton
                heading="Are you a content creator?"
                subText="If you are not sure, select No."
                onClickYes={() => {
                  setData((current) => ({ ...current, contentCreator: true }));
                  handleNext();
                }}
                onClickNo={() => {
                  setData((current) => ({ ...current, contentCreator: false }));
                  setProgress((current) => ({ ...current, key: 3 }));
                }}
              />
            )}
            {progress.key === 3 && (
              <StepUpload
                setProgress={setProgress}
                onNext={handleNext}
                setUploadedImage={setUploadedImage}
              />
            )}

            {progress.key === 4 && (
              <Step4
                showNextFace={showNextFace}
                onNext={handleNext}
                faces={faces}
                faceId={faceId}
              />
            )}

            {progress.key === 5 && (
              <StepEmail
                faces={faces}
                data={data}
                faceId={faceId}
                setProgress={setProgress}
                handleInputChange={handleInputChange}
                uploadedImage={uploadedImage}
              />
            )}

            {progress.key <= 5 &&
              !matchLoading &&
              !uploadsLoading &&
              !progressLoader && <AccountAlert />}

            {progress.matchFound ? (
              <>
                {progress.key === 6 &&
                  progress.needToShowGreatNews === false && (
                    <StepHiddenResult
                      count={count}
                      onNext={handleNext}
                      noExactMatch={progress.noExactMatch}
                      searches={searches}
                      setProgress={setProgress}
                    />
                  )}

                {progress.key === 7 &&
                  progress.needToShowGreatNews === false && (
                    <StepActionPermission
                      onNext={handleNext}
                      onClickNo={() => setExitStepper({ value: true, key: 1 })}
                      imageFound={progress.matchFound}
                    />
                  )}
              </>
            ) : (
              <>
                {progress.key === 7 &&
                  progress.needToShowGreatNews === true && (
                    <StepActionPermission
                      onNext={handleNext}
                      onClickNo={() => setExitStepper({ value: true, key: 1 })}
                      imageFound={false}
                    />
                  )}

                {progress.key === 7 &&
                  progress.needToShowGreatNews === false && (
                    <StepActionPermission
                      onNext={handleNext}
                      onClickNo={() => setExitStepper({ value: true, key: 1 })}
                      imageFound={progress.matchFound}
                    />
                  )}
              </>
            )}
            {progress.key === 8 && (
              <StepForm
                data={data}
                handleInputChange={handleInputChange}
                setData={setData}
                handleNext={handleNext}
                handleChange={handleChange}
                planDetails={getPlanInfo()}
                setProgress={setProgress}
                progress={progress}
              />
            )}
            {progress.key === 9 && (
              <CongrulationComponent plan={getPlanInfo().name} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
