import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  step1Container: {
    "& h2": {
      textAlign: "center",
      ...theme.typography.heading36,
      margin: 0,
      marginBottom: "25px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "5px",
        fontSize: "24px",
      },
    },
    "& p": {
      textAlign: "center",
      ...theme.typography.body24,
      margin: 0,
      marginBottom: "25px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },
  buttonsContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  yesButton: {
    marginRight: "7px !important",
    fontSize: "20px !important",
    height: "100px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
      marginRight: "0px !important",
      maxWidth: "240px !important",
      height: "auto !important",
    },
  },
  noButton: {
    marginLeft: "7px !important",
    fontSize: "20px !important",
    height: "100px !important",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px !important",
      fontSize: "16px !important",
      marginLeft: "0px !important",
      maxWidth: "240px !important",
      height: "auto !important",
    },
  },

  weight400: {
    fontWeight: "400 !important",
  },
}));

const StepType1 = ({
  onClickNo,
  onClickYes,
  heading,
  subText,
  inverseButton,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.step1Container}>
      <h2>{heading}</h2>
      <p>{subText}</p>
      <div className={classes.buttonsContainer}>
        <CustomButton
          yellow={!inverseButton}
          onClick={onClickYes}
          className={clsx(
            classes.yesButton,
            inverseButton ? classes.weight400 : ""
          )}
        >
          Yes
        </CustomButton>
        <CustomButton
          yellow={inverseButton}
          className={clsx(
            classes.noButton,
            !inverseButton ? classes.weight400 : ""
          )}
          onClick={onClickNo}
        >
          No
        </CustomButton>
      </div>
    </div>
  );
};

export default StepType1;
