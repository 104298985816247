import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import AlertCard from "../../Components/Alerts/AlertCard.jsx";
import MailIcon from "../../Assets/Images/alertMail_icon.png";

const useStyles = makeStyles((theme) => ({
  alertsContainer: {
    height: "100%",
  },

  link: {
    textDecoration: "none",
    color: theme.palette.lightGreen,
  },
  noAlertsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexDirection: "column",
  },
  mailIcon: {
    height: "100px",
    width: "100px",
  },
  noAlertsHeading: {
    textAlign: "center",
    padding: "5px 20px 5px 20px",
  },
  noAlertsBody: {
    textAlign: "center",
    padding: "0 20px",
  },
}));

const Alerts = () => {
  const classes = useStyles();

  const alerts = [
    // {
    //   isAlert: true,
    //   heading: "Concerning Image Detected",
    //   content: (
    //     <>
    //       We found a concerning image at this link
    //       <a
    //         href="https://reddit.com"
    //         target="_blank"
    //         rel="noreferrer"
    //         className={classes.link}
    //       >
    //         reddit.com.
    //       </a>{" "}
    //       Please click below to see if this image needs to be removed.
    //     </>
    //   ),
    //   timeAgo: "10 min ago",
    // },
    // {
    //   heading: "No Images Found",
    //   content:
    //     "We ran a search and found no matching images. We will update you again if we do.",
    //   timeAgo: "1 month ago",
    // },
  ];

  return (
    <div className={classes.alertsContainer}>
      {alerts.length > 0 ? (
        alerts.map((data, key) => (
          <AlertCard
            key={key}
            isAlert={data.isAlert}
            heading={data.heading}
            content={data.content}
            timeAgo={data.timeAgo}
          />
        ))
      ) : (
        <div className={classes.noAlertsContainer}>
          <img src={MailIcon} alt="mail_alert" className={classes.mailIcon} />
          <Typography variant="heading32" className={classes.noAlertsHeading}>
            Good news!
          </Typography>
          <Typography variant="body20" className={classes.noAlertsBody}>
            You have no alerts currently. We will email you if new images or
            videos appear online.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Alerts;
