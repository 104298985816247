import { makeStyles } from "@mui/styles";
import React from "react";
import { MdOutlineFileUpload } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  UploadMediaCardContainer: {
    background: theme.palette.lightGreen2,
    boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    padding: "14px 20px",
    height: "calc(100% - 28px)",
    cursor: "pointer",
  },
  innerLine: {
    margin: "10px",
    height: "calc(100% - 20px)",
    border: `1px dashed ${theme.palette.lightGreen}`,
    borderRadius: "5px",
  },
  uploadContainer: {
    background: theme.palette.lightGreen2,
    marginTop: "60px",
    width: "65vw",
    [theme.breakpoints.down("md")]: {
      marginTop: "45px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      width: "90vw",
    },
  },
  uploadWrap: {
    padding: "15px",
    height: "250px",
    cursor: "pointer",
  },
  uploadBorder: {
    border: "1px dashed #799995",
    borderRadius: "5px",
    height: "100%",
  },
  input: {
    display: "none",
  },
  text: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    "& h3": {
      ...theme.typography.body24,
      color: theme.palette.lightGreen,
    },
    "& svg": {
      fontSize: "30px",
      color: theme.palette.lightGreen,
      marginRight: "10px",
    },
  },
}));

const UploadCard = ({ handleUploadImage }) => {
  const classes = useStyles();
  return (
    <div className={classes.UploadMediaCardContainer}>
      <input
        className={classes.input}
        type="file"
        accept="image/*"
        id="image-upload"
        name="myImage"
        onChange={(event) => {
          handleUploadImage(event.target.files[0]);
        }}
      />
      <div className={classes.innerLine}>
        <label htmlFor="image-upload">
          <div className={classes.text}>
            <MdOutlineFileUpload />
            <h3 className="upload-file">Upload File</h3>
          </div>
        </label>
      </div>
    </div>
  );
};

export default UploadCard;
