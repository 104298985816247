export const SET_SUBSCRIPTIONS_LOADING = "SET_SUBSCRIPTIONS_LOADING";

export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const GET_SUBSCRIPTION_PLANS_SUCCESS = "GET_SUBSCRIPTION_PLANS_SUCCESS";
export const GET_SUBSCRIPTION_PLANS_FAILURE = "GET_SUBSCRIPTION_PLANS_FAILURE";

export const GET_ACTIVE_SUBSCRIPTION = "GET_ACTIVE_SUBSCRIPTION";
export const GET_ACTIVE_SUBSCRIPTION_SUCCESS =
  "GET_ACTIVE_SUBSCRIPTION_SUCCESS";
export const GET_ACTIVE_SUBSCRIPTION_FAILURE =
  "GET_ACTIVE_SUBSCRIPTION_FAILURE";

export const GET_BILLS = "GET_BILLS";
export const GET_BILLS_SUCCESS = "GET_BILLS_SUCCESS";
export const GET_BILLS_FAILURE = "GET_BILLS_FAILURE";

export const UPGRADE_SUBSCRIPTION = "UPGRADE_SUBSCRIPTION";
export const UPGRADE_SUBSCRIPTION_SUCCESS = "UPGRADE_SUBSCRIPTION_SUCCESS";
export const UPGRADE_SUBSCRIPTION_FAILURE = "UPGRADE_SUBSCRIPTION_FAILURE";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";
