import { makeStyles } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material";
import React from "react";
import CustomButton from "../UI/CustomButton.jsx";

const useStyles = makeStyles((theme) => ({
  content: {
    ...theme.typography.heading36,
    textAlign: "center",
    padding: "0 10px",
    margin: 0,
    marginBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "32px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
      fontSize: "28px",
    },
  },
  imageContainer: {
    height: "320px",
    width: "322px",
    [theme.breakpoints.down("md")]: {
      height: "320px",
      width: "322px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "322px",
    },
    "& img": {
      objectFit: "cover",
      height: "100%",
      width: "100%",
    },
  },
  buttonsContainer: {
    marginTop: "20px",
    width: "322px",
    [theme.breakpoints.down("md")]: {
      width: "322px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
    "& button": {
      width: "100%",
      textTransform: "none",
      height: "70px",
      borderRadius: "5px",
      boxShadow: "none  ",
      [theme.breakpoints.down("sm")]: {
        height: "50px",
        fontSize: "16px",
      },
    },
  },
  cancelButton: {
    marginTop: "10px !important",
  },
}));

const FaceCheck = ({ faces, faceId, handleNextPic, handleyes }) => {
  const classes = useStyles();
  return (
    <>
      <h2 className={classes.content}>Is this the correct person?</h2>
      <div className={classes.imageContainer}>
        <img src={faces[faceId].face_url} />
      </div>
      <StyledEngineProvider injectFirst>
        <div className={classes.buttonsContainer}>
          <CustomButton
            yellow
            variant="contained"
            onClick={handleyes}
            className={classes.saveButton}
          >
            Yes
          </CustomButton>
          <CustomButton
            variant="contained"
            onClick={handleNextPic}
            className={classes.cancelButton}
          >
            No
          </CustomButton>
        </div>
      </StyledEngineProvider>{" "}
    </>
  );
};

export default FaceCheck;
