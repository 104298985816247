// export const GET_MATCHES = "GET_MATCHES";
// export const GET_MATCHES_SUCCESS = "GET_MATCHES_SUCCESS";
// export const GET_MATCHES_FAILURE = "GET_MATCHES_FAILURE";

export const GET_DASHBOARD_MATCHES = "GET_DASHBOARD_MATCHES";
export const GET_DASHBOARD_MATCHES_SUCCESS = "GET_DASHBOARD_MATCHES_SUCCESS";
export const GET_DASHBOARD_MATCHES_FAILURE = "GET_DASHBOARD_MATCHES_FAILURE";

export const GET_FACES = "GET_FACES";
export const GET_FACES_SUCCESS = "GET_FACES_SUCCESS";
export const GET_FACES_FAILURE = "GET_FACES_FAILURE";

// export const GET_FACES_DASHBOARD = "GET_FACES_DASHBOARD";
// export const GET_FACES_DASHBOARD_SUCCESS = "GET_FACES_DASHBOARD_SUCCESS";
// export const GET_FACES_DASHBOARD_FAILURE = "GET_FACES_DASHBOARD_FAILURE";

export const SAVE_MATCH = "SAVE_MATCH";
export const SAVE_MATCH_SUCCESS = "SAVE_MATCH_SUCCESS";
export const SAVE_MATCH_FAILURE = "SAVE_MATCH_FAILURE";

export const GET_DASHBOARD_SEARCH = "GET_DASHBOARD_SEARCH";
export const GET_DASHBOARD_SEARCH_SUCCESS = "GET_DASHBOARD_SEARCH_SUCCESS";
export const GET_DASHBOARD_SEARCH_FAILURE = "GET_DASHBOARD_SEARCH_FAILURE";

export const DELETE_SEARCH = "DELETE_SEARCH";
export const DELETE_SEARCH_SUCCESS = "DELETE_SEARCH_SUCCESS";
export const DELETE_SEARCH_FAILURE = "DELETE_SEARCH_FAILURE";

export const SEARCH_ASSET = "SEARCH_ASSET";
export const SEARCH_ASSET_SUCCESS = "SEARCH_ASSET_SUCCESS";
export const SEARCH_ASSET_FAILURE = "SEARCH_ASSET_FAILURE";

export const SET_MATCHES_LOADING = "SET_MATCHES_LOADING";

export const CLEAR_MATCH_ERRORS = "CLEAR_MATCH_ERRORS";

export const START_PROGRESS_LOADER = "START_PROGRESS_LOADER";
export const STOP_PROGRESS_LOADER = "STOP_PROGRESS_LOADER";
export const SET_PROGRESS_VALUE = "SET_PROGRESS_VALUE";
