import React from "react";
import { StyledEngineProvider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import MailIcon from "../../Assets/Images/mail_icon.png";
import AuthWrapper from "../../Components/AuthWrapper/AuthWrapper.jsx";

const useStyles = makeStyles((theme) => ({
  backIcon: {
    fontSize: "25px",
    marginRight: "5px",
  },
  backContainer: {
    display: "flex",
    alignItems: "center",
    height: "20px",
    cursor: "pointer",
  },
  bodyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
    height: "calc(100% - 130px)",
    [theme.breakpoints.down("md")]: {
      padding: "15px",
      height: "calc(100% - 130px)",
    },
    [theme.breakpoints.down(325)]: {
      padding: "15px",
      height: "calc(100% - 150px)",
    },
  },
  bodyIcon: {
    height: "60px",
    width: "60px",
    marginBottom: "20px",
  },
  headText: {
    ...theme.typography.heading32,
    textAlign: "center",
  },
  bodyText: {
    ...theme.typography.body18,
    marginTop: "20px",
    textAlign: "center",
    marginBottom: "20px",
  },
  endText: {
    ...theme.typography.body18,
    marginTop: "10px",
    textAlign: "center",
    marginBottom: "10px",
    padding: "0 20px",
    "& .greenBold": {
      color: theme.palette.lightGreen,
      fontWeight: 700,
    },
  },
}));

const ForgotPasswordConfirmation = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <AuthWrapper hideBottomLine boxWithoutImage>
      <StyledEngineProvider injectFirst>
        <div className={classes.backContainer} onClick={() => handleBack()}>
          <IoIosArrowRoundBack className={classes.backIcon} />
          <Typography variant="body16_500">Back</Typography>
        </div>
        <div className={classes.bodyContainer}>
          <img src={MailIcon} alt="lock icon" className={classes.bodyIcon} />
          <Typography className={classes.headText}>
            Check Your E-Mail
          </Typography>
          <Typography className={classes.bodyText}>
            We sent instruction to your email to reset your password.
          </Typography>
        </div>
        <Typography className={classes.endText}>
          Did not receive the email? Please wait up to 5 minutes and check your
          <span className="greenBold"> spam folder.</span>
        </Typography>
      </StyledEngineProvider>
    </AuthWrapper>
  );
};

export default ForgotPasswordConfirmation;
