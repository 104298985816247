import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SecurityIcon from "../../Assets/Images/security_icon.png";
import { updateUserInfoAction } from "../../Store/User/actions";
import CustomButton from "../UI/CustomButton.jsx";
import CustomFormInput from "../UI/CustomFormInput.jsx";

const useStyles = makeStyles((theme) => ({
  takedownFromContainer: {
    height: "100%",
  },
  inputsContainer: {
    padding: "10px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  },

  takeDownNoContentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexDirection: "column",
  },
  content: {
    maxWidth: "550px",
    marginTop: "20px",
    ...theme.typography.body24,
    textAlign: "center",
    margin: 0,
  },
  buttonContainer: {
    minWidth: "200px",
    marginTop: "25px",
  },
}));

const TakedownForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);

  const [data, setData] = useState({
    fullName: "",
    country: "",
    streetAddress: "",
    suite: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    country: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
  });
  const [clicked, setClicked] = useState(false);

  const onChange = (e) => {
    setData((current) => ({ ...current, [e.target.name]: e.target.value }));
  };

  const inputs = [
    {
      label: "Full Name",
      type: "text",
      value: data.fullName,
      name: "fullName",
      error: Boolean(errors.fullName),
      helperText: errors.fullName,
    },
    {
      label: "Phone number",
      type: "number",
      value: data.phoneNumber,
      name: "phoneNumber",
      error: Boolean(errors.phoneNumber),
      helperText: errors.phoneNumber,
    },
    {
      label: "Country",
      type: "text",
      value: data.country,
      name: "country",
      error: Boolean(errors.country),
      helperText: errors.country,
    },

    {
      label: "Street Address",
      type: "text",
      value: data.streetAddress,
      name: "streetAddress",
      error: Boolean(errors.streetAddress),
      helperText: errors.streetAddress,
    },
    {
      label: "Suite / Unit",
      optional: true,
      type: "text",
      value: data.suite,
      name: "suite",
    },
    {
      label: "City",
      type: "text",
      value: data.city,
      name: "city",
      error: Boolean(errors.city),
      helperText: errors.city,
    },
    {
      label: "State",
      type: "text",
      value: data.state,
      name: "state",
      error: Boolean(errors.state),
      helperText: errors.state,
    },
    {
      label: "ZIP code",
      type: "text",
      value: data.zipCode,
      name: "zipCode",
      error: Boolean(errors.zipCode),
      helperText: errors.zipCode,
    },
  ];

  const handleValidate = () => {
    let isValid = true;
    const err = {
      fullName: "",
      country: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
    };

    if (!data.fullName) {
      isValid = false;
      err.fullName = "Full name is required.";
    }
    if (data.fullName) {
      if (!/^[a-zA-z\s]+$/.test(data.fullName)) {
        isValid = false;
        err.fullName = "Full name should only contain alphabets.";
      }
    }

    if (!data.country) {
      isValid = false;
      err.country = "Country is required.";
    }
    if (!data.streetAddress) {
      isValid = false;
      err.streetAddress = "Street is required.";
    }
    if (!data.city) {
      isValid = false;
      err.city = "City is required.";
    }
    if (!data.state) {
      isValid = false;
      err.state = "State is required.";
    }
    if (!data.zipCode) {
      isValid = false;
      err.zipCode = "Zip code is required.";
    }
    if (!data.phoneNumber) {
      isValid = false;
      err.phoneNumber = "Phone Number is required.";
    }

    setErrors(err);
    return isValid;
  };

  const handleFinish = () => {
    if (handleValidate()) {
      dispatch(
        updateUserInfoAction({
          full_name: data.fullName,
          country: data.country,
          street: data.streetAddress,
          suite: data.suite,
          city: data.city,
          state: data.state,
          zip_code: data.zipCode,
          phone_number: data.phoneNumber,
        })
      );
    }
  };

  useEffect(() => {
    setData((current) => ({
      ...current,
      fullName: userData?.full_name || "",
      country: userData?.address?.country || "",
      streetAddress: userData?.address?.street || "",
      suite: userData?.address?.suite || "",
      city: userData?.address?.city || "",
      state: userData?.address?.state || "",
      zipCode: userData?.address?.zip_code || "",
      phoneNumber: userData?.address?.phone_number || "",
    }));
  }, [userData]);

  return (
    <>
      {clicked ? (
        <div className={classes.takedownFromContainer}>
          <div className={classes.inputsContainer}>
            {inputs.map((e, i) => (
              <CustomFormInput onChange={onChange} key={i} {...e} />
            ))}
            <Grid container>
              <Grid
                item
                xs={4}
                sm={4}
                md={2}
                lg={2}
                className={classes.buttonContainer}
              >
                <CustomButton yellowFilled onClick={() => handleFinish()}>
                  Finish
                </CustomButton>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <div className={classes.takeDownNoContentContainer}>
          <img
            src={SecurityIcon}
            alt="security_icon"
            className={classes.icon}
          />
          <p className={classes.content}>
            In order to start the DMCA takedown process, please fill out this
            required information.
          </p>
          <div className={classes.buttonContainer}>
            <CustomButton yellowFilled onClick={() => setClicked(true)}>
              Get Started
            </CustomButton>
          </div>
        </div>
      )}
    </>
  );
};

export default TakedownForm;
