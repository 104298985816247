import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import {
  clearMatchErrorAction,
  deleteSearchAction,
  getDashBoardMatches,
} from "../../Store/Matches/actions";
import {
  addTakedownRequestAction,
  resetDMCAError,
} from "../../Store/DMCA/actions";
import MatchesCard from "../../Components/Matches/MatchesCard.jsx";
import DMCAPlanDialog from "../../Components/Matches/DMCAPlanDialog.jsx";
import Filter from "../../Components/UI/Filter.jsx";
import CustomPopup from "../../Components/UI/CustomPopup.jsx";
import CustomButton from "../../Components/UI/CustomButton.jsx";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import DeleteConfirmationDialog from "../../Components/Common/ConfirmationDialog.jsx";
import UpgradePlanBillingDialog from "../../Components/Common/UpgradePlanDialog.jsx";
import WebsiteDialog from "../../Components/Common/WebsiteDialog.jsx";

const useStyles = makeStyles((theme) => ({
  matchesContainer: {
    height: "100%",
  },

  bodyContainer: {
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  upgradeDialogContainer: {
    "& .MuiPaper-root": {
      padding: "10px",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
    },
  },

  websiteDialogContainer: {
    "& .MuiPaper-root": {
      padding: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
      "& .MuiBackdrop-root": {
        backdropFilter: "blur(45px)",
      },
    },
  },
  dialogHead: {
    ...theme.typography.heading24,
    fontWeight: "700",
    margin: 0,
    textAlign: "center",
    "& span": {
      color: theme.palette.yellow,
    },
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.heading20,
    },
  },
  dialogBodyFont: {
    ...theme.typography.body16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body14,
    },
  },
  continueButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  cancelButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    marginTop: "7px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

const Matches = () => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [websiteModal, setwebsiteModal] = React.useState(false);
  // const [upgradePlan, setUpgradePlan] = React.useState(false);
  const [takeDownModal, setTakeDownModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState();
  const [showUpgradePlanBillingDialog, setShowUpgradePlanBillingDialog] =
    React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState({
    image: false,
    video: false,
    websites: [],
  });

  const { matches, loading } = useSelector((state) => state.matches);
  const { userData } = useSelector((state) => state.user);

  const {
    dmca,
    isSubscriptionActive,
    error,
    errorMessage,
    errorTitle,
    loading: DmcaLoading,
  } = useSelector((state) => state.DMCA);

  const getMatches = useMemo(
    () =>
      matches
        .filter((e) => {
          if (filterOptions.image && filterOptions.video) {
            return true;
          }
          if (filterOptions.image) {
            return e.source_type === "image";
          }
          if (filterOptions.video) {
            return e.source_type === "video";
          }
          return true;
        })
        .filter((e) => {
          if (filterOptions.websites.length === 0) {
            return true;
          }
          return filterOptions.websites.includes(e.domain);
        }),
    [matches, filterOptions]
  );

  const openWebsiteModal = (website) => {
    setwebsiteModal(website);
  };

  const closeWebsiteModal = () => {
    setwebsiteModal(false);
  };

  const closeTakedownModal = () => {
    setTakeDownModal(false);
  };

  const openTakeDownModal = () => {
    setTakeDownModal(true);
  };

  const closeUpgradePlanDialog = () => {
    dispatch(resetDMCAError());
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const openDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleTakeDown = (e) => {
    setSelectedImage(e);
    openTakeDownModal();
  };

  const handleTakeDownConfirmation = () => {
    sessionStorage.setItem(
      "takedown",
      JSON.stringify({
        asset_id: selectedImage.asset_id,
        match_id: selectedImage._id,
        face: selectedImage.face,
        source_id: selectedImage.source_id,
        domain: selectedImage.domain,
        asset_url:
          selectedImage.source_type === "image"
            ? selectedImage.faceSignedUrl
            : selectedImage.frameSignedUrl,
        page_url: selectedImage.page_url,
      })
    );
    dispatch(
      addTakedownRequestAction(
        {
          asset_id: selectedImage.asset_id,
          match_id: selectedImage._id,
          face: selectedImage.face,
          source_id: selectedImage.source_id,
          domain: selectedImage.domain,
          asset_url:
            selectedImage.source_type === "image"
              ? selectedImage.faceSignedUrl
              : selectedImage.frameSignedUrl,
          page_url: selectedImage.page_url,
        },
        () => closeTakedownModal(),
        () => closeTakedownModal()
      )
    );
  };

  const handleDelete = (data) => {
    openDeleteModal();
    setSelectedImage(data);
  };

  const handleDeleteConfirmation = () => {
    dispatch(
      deleteSearchAction({
        face: selectedImage.face,
        source_id: selectedImage.source_id,
      })
    );
    closeDeleteModal();
  };

  const addPaymentSession = () => {
    const fscSession = {
      reset: true,
      products: [
        {
          path: "dmca-4",
          quantity: 1,
        },
      ],
      paymentContact: {
        email: userData.email_address,
        firstName: userData?.full_name?.split(" ")[0],
        lastName: userData?.full_name?.split(" ")[1],
        addressLine1: userData.street,
        addressLine2: userData.suite,
        city: userData.city,
        region: userData.state,
        country: userData.country,
        postalCode: userData.zip_code,
      },
    };

    window.fastspring.builder.push(fscSession);
    window.fastspring.builder.checkout();
  };

  const handleAddDMCAPayment = () => {
    addPaymentSession();
    closeUpgradePlanDialog();
  };

  useEffect(() => {
    dispatch(getDashBoardMatches());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearMatchErrorAction());
      setFilterOptions({ websites: [], video: false, image: false });
    },
    [dispatch]
  );

  return (
    <div className={classes.matchesContainer}>
      {loading || DmcaLoading ? (
        <div className={classes.loaderContainer}>
          <CustomSpinner />
        </div>
      ) : (
        <>
          <WebsiteDialog open={websiteModal} onClose={closeWebsiteModal} />
          <UpgradePlanDialog
            open={error && !isSubscriptionActive}
            heading={errorTitle}
            body={errorMessage}
            confirmText={errorTitle}
            cancelText="Cancel"
            continueButton={() => {
              setShowUpgradePlanBillingDialog(true);
              dispatch(resetDMCAError());
            }}
            onClose={closeUpgradePlanDialog}
          />

          <TakeDownConfirmationDialog
            open={takeDownModal}
            onClose={closeTakedownModal}
            handleTakeDownConfirmation={handleTakeDownConfirmation}
          />

          <UpgradePlanBillingDialog
            open={showUpgradePlanBillingDialog}
            onClose={() => setShowUpgradePlanBillingDialog(false)}
            reason="savedMatches"
          />
          <DeleteConfirmationDialog
            open={deleteModal}
            onClose={closeDeleteModal}
            handleConfirmation={handleDeleteConfirmation}
            heading="Are you sure this isn't you ?"
            body="We will not show you this result again."
            confirmButtonName="Yes"
            cancelButtonName="No"
          />
          <DMCAPlanDialog
            open={error && !dmca && isSubscriptionActive}
            onClose={closeUpgradePlanDialog}
            handleClick={handleAddDMCAPayment}
          />
          <Filter
            setFilterOptions={setFilterOptions}
            websites={matches
              .map((item) => item.domain)
              .filter((value, index, self) => self.indexOf(value) === index)}
          />

          <Grid container spacing={2} className={classes.bodyContainer}>
            {getMatches.map((e, key) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={key}>
                <MatchesCard
                  image={
                    e.source_type === "image" ? e.image_url : e.frameSignedUrl
                  }
                  website={e.domain}
                  sourceType={e.source_type}
                  distance={e.distance}
                  takedownId={e.takedown_id}
                  openModal={() => openWebsiteModal(e)}
                  onTakeDown={() => handleTakeDown(e)}
                  handleDelete={() => handleDelete(e)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
};

const UpgradePlanDialog = ({
  open,
  onClose,
  heading,
  body,
  confirmText,
  cancelText,
  continueButton,
}) => {
  const classes = useStyles();

  return (
    <CustomPopup
      open={Boolean(open)}
      onClose={onClose}
      className={classes.upgradeDialogContainer}
    >
      <h2 className={classes.dialogHead}>{heading}</h2>
      <p className={classes.dialogBodyFont}>{body}</p>
      <CustomButton
        yellow
        className={classes.continueButton}
        onClick={continueButton}
      >
        {confirmText}
      </CustomButton>
      <CustomButton className={classes.cancelButton} onClick={onClose}>
        {cancelText}
      </CustomButton>
    </CustomPopup>
  );
};

const TakeDownConfirmationDialog = ({
  open,
  onClose,
  handleTakeDownConfirmation,
}) => {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.DMCA);

  return (
    <CustomPopup
      open={Boolean(open)}
      onClose={onClose}
      className={classes.upgradeDialogContainer}
    >
      {loading ? (
        <CustomSpinner />
      ) : (
        <>
          <h2 className={classes.dialogHead}>
            Are you sure want to start take down process for this asset.
          </h2>
          <br />
          <CustomButton
            yellow
            className={classes.continueButton}
            onClick={handleTakeDownConfirmation}
          >
            Yes
          </CustomButton>
          <CustomButton className={classes.cancelButton} onClick={onClose}>
            No
          </CustomButton>
        </>
      )}
    </CustomPopup>
  );
};
export default Matches;
